import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

import { Share as CapacitorShare } from '@capacitor/share';

import { useClinic } from './ClinicContext';
import { useDentist } from '@/contexts/DentistContext';
import { useTracker } from '@/contexts/TrackerContext';
import {
  Share,
  createShare,
  deleteShare,
  getSharesByClinicId,
  getSharesByDentistId
} from '@/data/Shares';
import useToast from '@/hooks/useToast';
import { encodeShareCode } from '@/utils/helpers/encodeShareCode';
import { formatDentistName } from '@/utils/helpers/formatDentistName';
import { formatName } from '@/utils/helpers/formatName';

interface ShareContextType {
  shareLink: string;
  share: Share | null;
  onUpdateShareCode: (code: string) => Promise<void>;
  onShare: () => Promise<void>;
  loading: boolean;
}

const ShareContext = createContext<ShareContextType | undefined>(undefined);

export const ShareProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const displayToast = useToast();
  const { selectedClinic: clinic } = useClinic();
  const { selectedDentist: dentist } = useDentist();
  const { captureEvent } = useTracker();

  const [shares, setShares] = useState<Share[]>([]);
  const [share, setShare] = useState<Share | null>(null);
  const [shareLink, setShareLink] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const previousDentistIdRef = useRef<string | null>(null);
  const previousClinicIdRef = useRef<string | null>(null);

  const fetchShareLink = useCallback(async () => {
    if (
      !dentist?.id ||
      (dentist.id === previousDentistIdRef.current &&
        clinic?.id === previousClinicIdRef.current)
    )
      return;

    previousDentistIdRef.current = dentist.id;
    previousClinicIdRef.current = clinic?.id ?? null;

    try {
      const fetchedShares: Share[] = await getSharesByDentistId(dentist.id!);
      if (fetchedShares.length > 0) {
        const latestShare = fetchedShares[fetchedShares.length - 1];
        setShares(fetchedShares);
        setShare(latestShare);
        setShareLink(`https://www.odonto.ph/s/${latestShare.code}`);
      } else {
        if (clinic?.id) {
          const fetchedShares: Share[] = await getSharesByClinicId(clinic.id!);
          if (fetchedShares.length > 0) {
            const latestShare = fetchedShares[fetchedShares.length - 1];
            setShares(fetchedShares);
            setShare(latestShare);
            setShareLink(`https://www.odonto.ph/s/${latestShare.code}`);
          } else {
            setShares([]);
            setShare(null);
            setShareLink(`https://www.odonto.ph/schedule/${clinic.id}`);
          }
        }
      }
    } catch (error: any) {
      displayToast({
        message: `Please check your internet connection.`,
        duration: 3000,
        position: 'bottom',
        positionAnchor: 'tabBar'
      });
    }
  }, [
    dentist?.id,
    clinic?.id,
    previousDentistIdRef.current,
    previousClinicIdRef.current
  ]);

  useEffect(() => {
    fetchShareLink();
  }, [fetchShareLink]);

  const onUpdateShareCode = useCallback(
    async (code: string) => {
      const encodedCode = encodeShareCode(code);
      if (share && share.code === encodedCode) {
        displayToast({
          message:
            'The custom link you entered matched your previous one. Please enter a different link.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
        return;
      }
      try {
        setLoading(true);
        const duplicateShares = shares.filter(
          (share) => share.code === encodedCode
        );
        if (duplicateShares.length > 0) {
          for (const share of duplicateShares) {
            await deleteShare(share.code);
          }
        }
        const newShare = {
          code: encodedCode,
          clinic_id: clinic?.id!,
          dentist_id: dentist?.id
        } as Share;
        const createdShare = await createShare(newShare);
        if (createdShare) {
          setShares([...shares, newShare]);
          setShare(newShare);
          setShareLink(`https://www.odonto.ph/s/${encodedCode}`);
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        displayToast({
          message: `The booking link you entered is already in use. Please enter a different link.`,
          duration: 2400,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      }
    },
    [shares.length, share?.code, clinic?.id, dentist?.id]
  );

  const onShare = useCallback(async () => {
    try {
      await CapacitorShare.share({
        text: `Schedule your appointment with ${formatDentistName({
          dentist,
          returnDentistFullName: true
        })}: ${shareLink}`,
        dialogTitle: 'Share your booking link to add new appointments'
      });
      captureEvent({
        event: 'code shared',
        properties: { share_link: shareLink }
      });
    } catch (error: any) {
      if (!error.message.includes('cancel')) {
        displayToast({
          message: `${error.message}`,
          duration: 2400,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      }
    }
  }, [dentist?.first_name, dentist?.last_name, shareLink]);

  return (
    <ShareContext.Provider
      value={{ shareLink, share, onUpdateShareCode, onShare, loading }}
    >
      {children}
    </ShareContext.Provider>
  );
};

export const useShare = () => {
  const context = useContext(ShareContext);
  if (!context) {
    throw new Error('useShare must be used within a ShareProvider');
  }
  return context;
};
