import React from 'react';

import './WeeklyReportPatients.css';

interface WeeklyReportPatientsProps {
  header: string | React.ReactNode;
  patients: string[];
}

const WeeklyReportPatients: React.FC<WeeklyReportPatientsProps> = ({
  header,
  patients
}) => {
  if (patients.length === 0) return null;

  return (
    <div className="weeklyReportPatients">
      <h4 className="weeklyReportPatientsHeader">{header}</h4>
      <div className="weeklyReportPatientsList">
        {patients.sort().map((patient) => (
          <div key={patient} className="weeklyReportPatientsItem">
            {patient}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeeklyReportPatients;
