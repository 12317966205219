import {
  IonButton,
  IonContent,
  IonInput,
  IonLoading,
  IonModal
} from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Logo from '@/components/common/logo/Logo';
import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import Icon from '@/components/memo/icon/Icon';
import Text from '@/components/memo/text/Text';
import { useDentist } from '@/contexts/DentistContext';
import { updateDentist } from '@/data/Dentists';
import useToast from '@/hooks/useToast';
import { formatName } from '@/utils/helpers/formatName';
import { arrowBackOutline, checkmarkCircleOutline } from 'ionicons/icons';

import './UpdateName.css';

interface UpdateNameProps {
  isOpen: boolean;
  onClose: () => void;
  presentingElement?: HTMLElement | undefined;
}

const UpdateName: React.FC<UpdateNameProps> = ({
  isOpen,
  onClose,
  presentingElement
}) => {
  const displayToast = useToast();
  const { selectedDentist: dentist, setSelectedDentist } = useDentist();

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (dentist) {
      setFirstName(dentist.first_name);
      setLastName(dentist.last_name);
    }
  }, [dentist?.first_name, dentist?.last_name]);

  const copy = {
    subHeader: 'DENTIST NAME',
    header: (
      <>
        Edit <Text color="primary">name</Text>
      </>
    ),
    message: 'Please enter your first name and last name below.',
    buttonText: 'Update',
    firstNameLabel: 'First Name',
    firstNamePlaceholder: 'First Name',
    lastNameLabel: 'Last Name',
    lastNamePlaceholder: 'Last Name'
  };

  const onNext = useCallback(async () => {
    if (!firstName) {
      setFirstNameError(true);
      displayToast({
        message: 'Please enter your first name.',
        duration: 3000,
        position: 'bottom',
        positionAnchor: 'nextButton'
      });
      return;
    }

    if (!lastName) {
      setLastNameError(true);
      displayToast({
        message: 'Please enter your last name.',
        duration: 3000,
        position: 'bottom',
        positionAnchor: 'nextButton'
      });
      return;
    }

    const formattedFirstName = formatName(firstName);
    const formattedLastName = formatName(lastName);

    if (dentist?.id) {
      try {
        setLoading(true);
        await updateDentist({
          dentistId: dentist?.id,
          dentist: {
            ...dentist,
            first_name: formattedFirstName,
            last_name: formattedLastName
          }
        });
        setSelectedDentist({
          ...dentist,
          first_name: formattedFirstName,
          last_name: formattedLastName
        });
        setLoading(false);
        displayToast({
          message: 'Name updated successfully.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
        onClose();
      } catch {
        setLoading(false);
        displayToast({
          message: 'Something went wrong. Please try again.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      }
    }
  }, [firstName, lastName, dentist?.id, updateDentist, onClose]);

  const onCloseModal = useCallback(() => {
    setFirstNameError(false);
    setLastNameError(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    return () => {
      setLoading(false);
      setFirstNameError(false);
      setLastNameError(false);
    };
  }, []);

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onCloseModal}
      presentingElement={presentingElement}
    >
      <IonContent>
        <Padding withSafeArea={!presentingElement}>
          <div id="updatePatientVerification">
            <Logo leftIcon={arrowBackOutline} onClickLeftIcon={onClose} />
            <Spacer top={0} bottom={24}>
              <h4 id="subHeader">{copy.subHeader}</h4>
              <h1 id="header">{copy.header}</h1>
              <p id="message">{copy.message}</p>
            </Spacer>

            <Spacer bottom={124}>
              <div id="form">
                <div className={`input ${firstNameError ? 'error' : ''}`}>
                  <IonInput
                    label={copy.firstNameLabel}
                    labelPlacement="stacked"
                    placeholder={copy.firstNamePlaceholder}
                    value={firstName}
                    onIonInput={(e: CustomEvent) =>
                      setFirstName(e.detail.value)
                    }
                  />
                </div>
                <div className={`input ${lastNameError ? 'error' : ''}`}>
                  <IonInput
                    label={copy.lastNameLabel}
                    labelPlacement="stacked"
                    placeholder={copy.lastNamePlaceholder}
                    value={lastName}
                    onIonInput={(e: CustomEvent) => setLastName(e.detail.value)}
                  />
                </div>
              </div>
            </Spacer>

            <ButtonContainer>
              <IonButton
                id="nextButton"
                onClick={onNext}
                expand="block"
                fill="solid"
                size="default"
                color="primary"
                strong={true}
                disabled={loading}
              >
                <Icon slot="start" icon={checkmarkCircleOutline} />
                {copy.buttonText}
              </IonButton>
            </ButtonContainer>
          </div>
        </Padding>
        <IonLoading isOpen={loading} message="Loading..." spinner="crescent" />
      </IonContent>
    </IonModal>
  );
};

export default UpdateName;
