import React from 'react';

import Spacer from '@/components/common/spacer/Spacer';
import Text from '@/components/memo/text/Text';

import './Header.css';

const Header: React.FC = () => {
  const copy = {
    title: (
      <>
        <Text color="primary">Level up</Text> your practice!
      </>
    ),
    description: (
      <>
        Less admin work. More time for you.
        <br />
        Less stress. More revenue.
      </>
    )
  };

  return (
    <div className="header">
      <h1 className="title">{copy.title}</h1>
      <Spacer top={8} />
      <p className="description">{copy.description}</p>
    </div>
  );
};

export default Header;
