import React from 'react';

import Icon from '@/components/memo/icon/Icon';
import { addCircle } from 'ionicons/icons';

import './AddDentistTile.css';

interface AddDentistTileProps {
  onAddDentist: () => void;
  label: string;
}

const AddDentistTile: React.FC<AddDentistTileProps> = ({
  onAddDentist,
  label
}) => {
  return (
    <div id="addDentistTile" onClick={onAddDentist}>
      <Icon id="addDentistTileIcon" icon={addCircle} color="primary" />
      <p id="addDentistTileLabel">{label}</p>
    </div>
  );
};

export default AddDentistTile;
