import { IonContent, IonLoading, IonPage } from '@ionic/react';
import React from 'react';

import ClinicName from '@/components/auth/getStarted/clinicName/ClinicName';
import MobileNumber from '@/components/auth/getStarted/mobileNumber/MobileNumber';
import Verification from '@/components/auth/getStarted/verification/Verification';
import Logo from '@/components/common/logo/Logo';
import Padding from '@/components/common/padding/Padding';
import ProgressBar from '@/components/common/progressBar/ProgressBar';
import {
  GetStartedProvider,
  useGetStarted
} from '@/contexts/GetStartedContext';
import { GET_STARTED_STEPS } from '@/utils/constants';
import { arrowBackOutline } from 'ionicons/icons';

import './GetStarted.css';

const GetStarted: React.FC = () => {
  const { loading, currentStep, setCurrentStep, goBack } = useGetStarted();

  const onBack = () => {
    if (currentStep === GET_STARTED_STEPS.CLINIC_NAME) {
      goBack();
      return;
    }

    const previousStepIndex =
      Object.values(GET_STARTED_STEPS).findIndex(
        (step) => step === currentStep
      ) - 1;
    const newStep = Object.values(GET_STARTED_STEPS)[previousStepIndex];
    if (newStep) {
      setCurrentStep(newStep);
    }
  };

  const renderGetStarted = () => {
    const totalSteps = Object.keys(GET_STARTED_STEPS).length;
    const currentStepIndex =
      Object.values(GET_STARTED_STEPS).indexOf(currentStep);

    const stepComponents = {
      [GET_STARTED_STEPS.CLINIC_NAME]: <ClinicName />,
      [GET_STARTED_STEPS.MOBILE_NUMBER]: <MobileNumber />,
      [GET_STARTED_STEPS.VERIFICATION]: <Verification />
    };

    return (
      <>
        <ProgressBar
          totalSteps={totalSteps}
          currentProgress={currentStepIndex}
        />
        {stepComponents[currentStep]}
      </>
    );
  };

  return (
    <IonPage id="getStarted">
      <IonContent>
        <Padding>
          <Logo leftIcon={arrowBackOutline} onClickLeftIcon={onBack} />
          {renderGetStarted()}
        </Padding>
      </IonContent>

      <IonLoading isOpen={loading} message="Loading..." spinner="crescent" />
    </IonPage>
  );
};

const GetStartedWithProvider: React.FC = () => (
  <GetStartedProvider>
    <GetStarted />
  </GetStartedProvider>
);

export default GetStartedWithProvider;
