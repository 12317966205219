import React from 'react';

import Avatar from '@/components/common/avatar/Avatar';
import Skeleton from '@/components/memo/skeleton/Skeleton';

import './DentistTilePlaceholder.css';

const DentistTilePlaceholder: React.FC = () => {
  return (
    <div className="dentistTilePlaceholder">
      <Avatar className="dentistAvatar" loading />
      <p className="dentistName">
        <Skeleton className="skeletonName" />
      </p>
    </div>
  );
};

export default DentistTilePlaceholder;
