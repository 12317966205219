import React from 'react';

import Skeleton from '@/components/memo/skeleton/Skeleton';
import { AvatarColor } from '@/utils/types';

import './Avatar.css';

interface AvatarProps {
  color?: AvatarColor | null;
  image?: string | null;
  className?: string;
  initials?: string;
  loading?: boolean;
}

const Avatar: React.FC<AvatarProps> = ({
  color,
  image,
  className,
  initials,
  loading
}) => {
  return (
    <div className={`avatarContainer ${className ? className : ''}`}>
      <div
        className={`avatar ${color ? color : ''}`}
        style={image ? { backgroundImage: `url(${image})` } : {}}
      >
        {loading ? (
          <Skeleton className="skeletonAvatar" />
        ) : (
          <svg
            viewBox="0 0 36 36"
            preserveAspectRatio="xMidYMid meet"
            style={{ width: '100%', height: '100%' }}
          >
            <text
              x="50%"
              y="53%"
              dominantBaseline="middle"
              textAnchor="middle"
              style={{
                fontSize: '100%',
                fontWeight: 600,
                fill: 'var(--ion-color-light)'
              }}
            >
              {initials}
            </text>
          </svg>
        )}
      </div>
    </div>
  );
};

export default Avatar;
