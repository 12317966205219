import { maleOutline } from "ionicons/icons";
import { maleFemaleOutline } from "ionicons/icons";
import { femaleOutline } from "ionicons/icons";

import { GENDER_OPTIONS } from "@/utils/constants";
import { Gender } from "@/utils/types";

export const getGenderIcon = (gender: Gender | null | undefined) => {
  switch (gender) {
    case GENDER_OPTIONS.FEMALE:
      return femaleOutline;
    case GENDER_OPTIONS.OTHERS:
      return maleFemaleOutline;
    default:
      return maleOutline;
  }
};
