// sort-imports-ignore
import {
  IonApp,
  IonBadge,
  IonLabel,
  IonLoading,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { Capacitor } from '@capacitor/core';

import DeepLinkListener from '@/components/listeners/deeplink/DeepLinkListener';
import Icon from '@/components/memo/icon/Icon';
import Notifications from '@/components/dashboard/notifications/Notifications';
import { AuthProvider, useAuth } from '@/contexts/AuthenticationContext';
import { ClinicProvider, useClinic } from '@/contexts/ClinicContext';
import { DentistProvider, useDentist } from '@/contexts/DentistContext';
import { NetworkProvider, useNetwork } from '@/contexts/NetworkContext';
import {
  NotificationsProvider,
  useNotifications
} from '@/contexts/NotificationsContext';
import { RequestsProvider, useRequests } from '@/contexts/RequestsContext';
import { ShareProvider } from '@/contexts/ShareContext';
import {
  SubscriptionProvider,
  useSubscription
} from '@/contexts/SubscriptionContext';
import { TabsProvider, useTabs } from '@/contexts/TabsContext';
import { TrackerProvider } from '@/contexts/TrackerContext';
import { VersionProvider, useVersion } from '@/contexts/VersionContext';
import { WeeklyReportsProvider } from '@/contexts/WeeklyReportsContext';
import useBackButton from '@/hooks/useBackButton';
import AddDentist from '@/pages/profiles/addDentist/AddDentist';
import Calendar from '@/pages/dashboard/calendar/Calendar';
import GetStarted from '@/pages/authentication/getStarted/GetStarted';
import Maintenance from '@/pages/overlays/maintenance/Maintenance';
import NoAccess from '@/pages/overlays/noAccess/NoAccess';
import NoInternet from '@/pages/overlays/noInternet/NoInternet';
import Paywall from '@/pages/subscription/paywall/Paywall';
import Profile from '@/pages/dashboard/profile/Profile';
import Requests from '@/pages/dashboard/requests/Requests';
import SelectDentist from '@/pages/profiles/selectDentist/SelectDentist';
import SignIn from '@/pages/authentication/signIn/SignIn';
import Update from '@/pages/overlays/update/Update';
import Welcome from '@/pages/authentication/welcome/Welcome';
import { ACCESS_TYPES } from '@/utils/constants';
import { ROUTES } from '@/utils/routes';
import { TABS } from '@/utils/tabs';
import { IonReactRouter } from '@ionic/react-router';
import {
  calendarClear,
  calendarClearOutline,
  fileTray,
  fileTrayOutline,
  person,
  personOutline
} from 'ionicons/icons';
import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';

/* Theme variables */
import '@/theme/variables.css';

setupIonicReact({
  mode: 'ios'
});

interface OverlaysProps {
  connected?: boolean | null | undefined;
  forceUpdate?: boolean | null | undefined;
  noAccess?: boolean | null | undefined;
}

interface SubscriptionProps {}

interface AuthenticationProps {}

interface ProfilesProps {}

interface DashboardProps {
  pendingRequestsCount: number;
}

const Overlays: React.FC<OverlaysProps> = ({
  connected,
  forceUpdate,
  noAccess
}) => {
  const renderRootContent = () => {
    if (noAccess) {
      return <NoAccess />;
    }

    if (!connected) {
      return <Redirect to={ROUTES.NO_INTERNET} />;
    }

    if (forceUpdate) {
      return <Redirect to={ROUTES.UPDATE} />;
    }

    return <Redirect to={ROUTES.MAINTENANCE} />;
  };

  return (
    <IonRouterOutlet animated={false}>
      <Route exact path={ROUTES.ROOT}>
        {renderRootContent()}
      </Route>
      <Route exact path={ROUTES.NO_INTERNET}>
        <NoInternet />
      </Route>
      <Route exact path={ROUTES.UPDATE}>
        <Update />
      </Route>
      <Route exact path={ROUTES.MAINTENANCE}>
        <Maintenance />
      </Route>
      {/* Routes from other screens */}
      <Route exact path={ROUTES.GET_STARTED}>
        <Redirect to={ROUTES.ROOT} />
      </Route>
      <Route exact path={ROUTES.SIGN_IN}>
        <Redirect to={ROUTES.ROOT} />
      </Route>
      <Route exact path={ROUTES.REQUESTS}>
        <Redirect to={ROUTES.ROOT} />
      </Route>
      <Route exact path={ROUTES.PROFILE}>
        <Redirect to={ROUTES.ROOT} />
      </Route>
      <Route exact path={ROUTES.ADD_DENTIST}>
        <Redirect to={ROUTES.ROOT} />
      </Route>
    </IonRouterOutlet>
  );
};

const Subscription: React.FC<SubscriptionProps> = () => {
  return (
    <IonRouterOutlet animated={false}>
      <Route path="*">
        <Paywall />
      </Route>
    </IonRouterOutlet>
  );
};

const Authentication: React.FC<AuthenticationProps> = () => {
  return (
    <IonRouterOutlet>
      <Route exact path={ROUTES.ROOT}>
        <Welcome />
      </Route>
      <Route exact path={ROUTES.GET_STARTED}>
        <GetStarted />
      </Route>
      <Route exact path={ROUTES.SIGN_IN}>
        <SignIn />
      </Route>
      {/* Routes from other screens */}
      <Route exact path={ROUTES.REQUESTS}>
        <Redirect to={ROUTES.ROOT} />
      </Route>
      <Route exact path={ROUTES.PROFILE}>
        <Redirect to={ROUTES.ROOT} />
      </Route>
      <Route exact path={ROUTES.ADD_DENTIST}>
        <Redirect to={ROUTES.ROOT} />
      </Route>
      <Route exact path={ROUTES.NO_INTERNET}>
        <Redirect to={ROUTES.ROOT} />
      </Route>
      <Route exact path={ROUTES.UPDATE}>
        <Redirect to={ROUTES.ROOT} />
      </Route>
      <Route exact path={ROUTES.MAINTENANCE}>
        <Redirect to={ROUTES.ROOT} />
      </Route>
    </IonRouterOutlet>
  );
};

const Profiles: React.FC<ProfilesProps> = () => (
  <IonRouterOutlet animated={false}>
    <Route exact path={ROUTES.ROOT}>
      <SelectDentist />
    </Route>
    <Route exact path={ROUTES.ADD_DENTIST}>
      <AddDentist />
    </Route>
    {/* Routes from other screens */}
    <Route exact path={ROUTES.REQUESTS}>
      <Redirect to={ROUTES.ROOT} />
    </Route>
    <Route exact path={ROUTES.PROFILE}>
      <Redirect to={ROUTES.ROOT} />
    </Route>
    <Route exact path={ROUTES.GET_STARTED}>
      <Redirect to={ROUTES.ROOT} />
    </Route>
    <Route exact path={ROUTES.SIGN_IN}>
      <Redirect to={ROUTES.ROOT} />
    </Route>
    <Route exact path={ROUTES.NO_INTERNET}>
      <Redirect to={ROUTES.ROOT} />
    </Route>
    <Route exact path={ROUTES.UPDATE}>
      <Redirect to={ROUTES.ROOT} />
    </Route>
    <Route exact path={ROUTES.MAINTENANCE}>
      <Redirect to={ROUTES.ROOT} />
    </Route>
  </IonRouterOutlet>
);

const Dashboard: React.FC<DashboardProps> = ({ pendingRequestsCount = 0 }) => {
  const { selectedTab, onTabButtonClick, onTabChange } = useTabs();

  return (
    <IonTabs onIonTabsDidChange={onTabChange}>
      <IonRouterOutlet animated={false}>
        <Route exact path={ROUTES.ROOT}>
          <Calendar />
        </Route>
        <Route exact path={ROUTES.REQUESTS}>
          <Requests />
        </Route>
        <Route exact path={ROUTES.PROFILE}>
          <Profile />
        </Route>
        {/* Routes from other screens */}
        <Route exact path={ROUTES.GET_STARTED}>
          <Redirect to="/" />
        </Route>
        <Route exact path={ROUTES.SIGN_IN}>
          <Redirect to="/" />
        </Route>
        <Route exact path={ROUTES.ADD_DENTIST}>
          <Redirect to="/" />
        </Route>
        <Route exact path={ROUTES.NO_INTERNET}>
          <Redirect to="/" />
        </Route>
        <Route exact path={ROUTES.UPDATE}>
          <Redirect to="/" />
        </Route>
        <Route exact path={ROUTES.MAINTENANCE}>
          <Redirect to="/" />
        </Route>
      </IonRouterOutlet>
      <IonTabBar id="tabBar" slot="bottom" mode="md">
        <IonTabButton
          tab={TABS.CALENDAR}
          href={ROUTES.CALENDAR}
          selected={selectedTab === TABS.CALENDAR}
          onClick={() => onTabButtonClick(TABS.CALENDAR)}
        >
          <Icon
            aria-hidden="true"
            icon={
              selectedTab === TABS.CALENDAR
                ? calendarClear
                : calendarClearOutline
            }
            color={selectedTab === TABS.CALENDAR ? 'primary' : 'default'}
          />
          <IonLabel>Calendar</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab={TABS.REQUESTS}
          href={ROUTES.REQUESTS}
          selected={selectedTab === TABS.REQUESTS}
          onClick={() => onTabButtonClick(TABS.REQUESTS)}
        >
          <Icon
            aria-hidden="true"
            icon={selectedTab === TABS.REQUESTS ? fileTray : fileTrayOutline}
            color={selectedTab === TABS.REQUESTS ? 'primary' : 'default'}
          />
          {pendingRequestsCount > 0 && (
            <IonBadge color="danger">{pendingRequestsCount}</IonBadge>
          )}
          <IonLabel>Requests</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab={TABS.PROFILE}
          href={ROUTES.PROFILE}
          selected={selectedTab === TABS.PROFILE}
          onClick={() => onTabButtonClick(TABS.PROFILE)}
        >
          <Icon
            aria-hidden="true"
            icon={selectedTab === TABS.PROFILE ? person : personOutline}
            color={selectedTab === TABS.PROFILE ? 'primary' : 'default'}
          />
          <IonLabel>Profile</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

const Screens: React.FC = () => {
  const { connected } = useNetwork();
  const { forceUpdate, maintenanceModeEnabled } = useVersion();
  const {
    isSubscribed,
    plans,
    purchasing,
    loading: loadingSubscription
  } = useSubscription();
  const { authenticated, loading: loadingAuth, user } = useAuth();
  const { selectedDentist } = useDentist();
  const { selectedClinic, isClinic } = useClinic();
  const {
    isNotificationsOpen,
    onCloseNotifications,
    notificationsPresentingElement
  } = useNotifications();
  const { pendingRequestsCount } = useRequests();

  /* Back button handler */
  useBackButton();

  /* Overlays */
  if (!connected || forceUpdate || maintenanceModeEnabled) {
    return <Overlays connected={connected} forceUpdate={forceUpdate} />;
  }

  /* Paywall */
  if (
    authenticated &&
    !loadingAuth &&
    !loadingSubscription &&
    isSubscribed === false &&
    isClinic &&
    plans.length > 0 &&
    Capacitor.isNativePlatform()
  ) {
    return (
      <>
        <Subscription />
        <IonLoading
          isOpen={purchasing}
          message={'Loading...'}
          spinner="crescent"
        />
      </>
    );
  }

  return (
    <>
      {/* Deep Link Listener */}
      <DeepLinkListener />

      {/* Authentication */}
      {!authenticated && <Authentication />}

      {/* Profiles */}
      {authenticated && (!selectedDentist || !selectedClinic) && <Profiles />}

      {/* No Access */}
      {authenticated &&
        selectedDentist &&
        user &&
        selectedClinic &&
        selectedDentist.access === ACCESS_TYPES.NONE &&
        user.id !== selectedClinic.id && <Overlays noAccess={true} />}

      {/* Dashboard */}
      {authenticated &&
        selectedDentist &&
        user &&
        selectedClinic &&
        (selectedDentist.access !== ACCESS_TYPES.NONE ||
          user.id === selectedClinic.id) && (
          <>
            <Dashboard pendingRequestsCount={pendingRequestsCount} />
            <Notifications
              isOpen={isNotificationsOpen}
              onClose={onCloseNotifications}
              presentingElement={notificationsPresentingElement}
            />
          </>
        )}

      {/* Loading */}
      <IonLoading
        isOpen={loadingAuth}
        message={'Loading...'}
        spinner="crescent"
      />
    </>
  );
};

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <NetworkProvider>
        <VersionProvider>
          <TrackerProvider>
            <AuthProvider>
              <SubscriptionProvider>
                <ClinicProvider>
                  <DentistProvider>
                    <ShareProvider>
                      <RequestsProvider>
                        <TabsProvider>
                          <NotificationsProvider>
                            <WeeklyReportsProvider>
                              <Screens />
                            </WeeklyReportsProvider>
                          </NotificationsProvider>
                        </TabsProvider>
                      </RequestsProvider>
                    </ShareProvider>
                  </DentistProvider>
                </ClinicProvider>
              </SubscriptionProvider>
            </AuthProvider>
          </TrackerProvider>
        </VersionProvider>
      </NetworkProvider>
    </IonReactRouter>
  </IonApp>
);

export default App;
