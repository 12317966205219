import React, {
  KeyboardEventHandler,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { Dialog } from '@capacitor/dialog';

import { useDentist } from '@/contexts/DentistContext';
import { useRequests } from '@/contexts/RequestsContext';
import { useTracker } from '@/contexts/TrackerContext';
import { Patient, createPatient } from '@/data/Patients';
import { Request, createRequest } from '@/data/Requests';
import { getDefaultServices, getServicesByDentistId } from '@/data/Services';
import { useInputFocus } from '@/hooks/useInputFocus';
import useToast from '@/hooks/useToast';
import {
  OFF_DUTY_PATIENT_ID,
  PURPOSE_JOIN_SEPARATOR,
  REQUEST_STATUS_OPTIONS,
  SELECT_MODAL_OTHERS
} from '@/utils/constants';
import { formatName } from '@/utils/helpers/formatName';
import { Gender } from '@/utils/types';
import moment from 'moment';

interface ManualBookingContextProps {
  // Date and Time
  date: string | null;
  setDate: React.Dispatch<React.SetStateAction<string | null>>;
  startTime: string | null;
  setStartTime: React.Dispatch<React.SetStateAction<string | null>>;
  endTime: string | null;
  setEndTime: React.Dispatch<React.SetStateAction<string | null>>;
  timeOptions: string[];

  // Purpose
  purpose: string | null;
  setPurpose: React.Dispatch<React.SetStateAction<string | null>>;
  othersPurpose: string | null;
  setOthersPurpose: React.Dispatch<React.SetStateAction<string | null>>;
  purposeOptions: string[];
  setPurposeOptions: React.Dispatch<React.SetStateAction<string[]>>;
  isPurposeSelected: (value: string) => boolean;
  isPurposePreSelected: (value: string) => boolean;
  onSelectPurpose: (value: string, isSelected: boolean) => void;
  isPurposeSelectModalOpen: boolean;
  setIsPurposeSelectModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClosePurposeSelectModal: () => void;

  // Patient Search
  patients: Patient[];
  setPatients: React.Dispatch<React.SetStateAction<Patient[]>>;
  isPatientSearchModalOpen: boolean;
  setIsPatientSearchModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onPressPatient: (patient: Patient) => void;
  onClosePatientSearchModal: () => void;

  // Patient Information
  firstName: string | null;
  setFirstName: React.Dispatch<React.SetStateAction<string | null>>;
  lastName: string | null;
  setLastName: React.Dispatch<React.SetStateAction<string | null>>;
  mobileNumber: string | null;
  setMobileNumber: React.Dispatch<React.SetStateAction<string | null>>;
  gender: Gender | null;
  setGender: React.Dispatch<React.SetStateAction<Gender | null>>;
  birthday: string | null;
  setBirthday: React.Dispatch<React.SetStateAction<string | null>>;

  // Miscellaneous
  errors: any;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onInput: (field: string, value: any) => void;
  submit: () => Promise<void>;
  reset: () => void;
  onKeyDown: KeyboardEventHandler<HTMLIonInputElement>;
  conflictingRequest: Request | null;
  inputRef: React.RefObject<HTMLIonInputElement>;
}

const ManualBookingContext = createContext<
  ManualBookingContextProps | undefined
>(undefined);

interface ManualBookingProviderProps {
  isOpen?: boolean;
  onClose?: () => void;
  selectedDate?: Date | string | null | undefined;
  setSelectedDate?: (date: Date) => void;
  children: React.ReactNode;
}

export const ManualBookingProvider: React.FC<ManualBookingProviderProps> = ({
  isOpen,
  onClose,
  selectedDate,
  setSelectedDate,
  children
}) => {
  const { selectedDentist: dentist, dentistHours } = useDentist();
  const { requests, setRequests, setFocusedRequestId } = useRequests();
  const { captureEvent } = useTracker();
  const displayToast = useToast();

  // Date and Time
  const [date, setDate] = useState<string | null>(null);
  const [startTime, setStartTime] = useState<string | null>(null);
  const [endTime, setEndTime] = useState<string | null>(null);
  const inputRef = useRef<HTMLIonInputElement>(null);

  // Purpose
  const [purpose, setPurpose] = useState<string | null>(null);
  const [othersPurpose, setOthersPurpose] = useState<string | null>(null);
  const [purposeOptions, setPurposeOptions] = useState<string[]>([]);
  const [lastSavedPurpose, setLastSavedPurpose] = useState<string | null>(null);
  const [isPurposeSelectModalOpen, setIsPurposeSelectModalOpen] =
    useState<boolean>(false);

  // Patient Search
  const [patients, setPatients] = useState<Patient[]>([]);
  const [isPatientSearchModalOpen, setIsPatientSearchModalOpen] =
    useState<boolean>(false);

  // Patient Information
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [mobileNumber, setMobileNumber] = useState<string | null>(null);
  const [gender, setGender] = useState<Gender | null>(null);
  const [birthday, setBirthday] = useState<string | null>(null);

  // Miscellaneous
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, boolean>>({
    date: false,
    startTime: false,
    endTime: false,
    purpose: false,
    othersPurpose: false,
    firstName: false,
    lastName: false,
    mobileNumber: false
  });
  const [conflictingRequest, setConflictingRequest] = useState<Request | null>(
    null
  );

  useInputFocus(inputRef);

  const getTimeOptions = (start: string, end: string) => {
    const startTime = moment(start, 'HH:mm');
    const endTime = moment(end, 'HH:mm');
    const calculatedTimeOptions = [];

    while (startTime.isBefore(endTime)) {
      calculatedTimeOptions.push(startTime.format('HH:mm'));
      startTime.add(30, 'minutes');
    }

    return calculatedTimeOptions;
  };

  const timeOptions = useMemo(() => {
    if (dentistHours.length > 0) {
      const dayOfWeek = moment(date).day();
      const hoursForDay = dentistHours.find((hours) => hours.day === dayOfWeek);
      if (hoursForDay && hoursForDay.start_time && hoursForDay.end_time) {
        return getTimeOptions(hoursForDay.start_time, hoursForDay.end_time);
      }
    }
    return Array.from({ length: 48 }, (_, i) => {
      const hours = String(Math.floor((i + 8) / 2) % 24).padStart(2, '0');
      const minutes = (i + 12) % 2 === 0 ? '00' : '30';
      return `${hours}:${minutes}`;
    });
  }, [dentistHours, date]);

  const onInput = (field: string, value: any) => {
    switch (field) {
      case 'date':
        setDate(value);
        break;
      case 'startTime':
        setStartTime(value);
        break;
      case 'endTime':
        setEndTime(value);
        break;
      case 'purpose':
        setPurpose(value);
        break;
      case 'othersPurpose':
        setOthersPurpose(value);
        break;
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'mobileNumber':
        setMobileNumber(value);
        break;
      case 'gender':
        setGender(value);
        break;
      case 'birthday':
        setBirthday(value);
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
  };

  const reset = () => {
    setDate(
      selectedDate && moment(selectedDate).isValid()
        ? moment(selectedDate).startOf('day').format('YYYY-MM-DD')
        : null
    );
    setStartTime(null);
    setEndTime(null);
    setPurpose(null);
    setOthersPurpose(null);
    setFirstName(null);
    setLastName(null);
    setMobileNumber(null);
    setGender(null);
    setBirthday(null);
    setErrors({
      date: false,
      startTime: false,
      endTime: false,
      purpose: false,
      othersPurpose: false,
      firstName: false,
      lastName: false,
      mobileNumber: false
    });
  };

  const submit = useCallback(async () => {
    try {
      const newErrors = {
        date: !date,
        startTime: !startTime,
        endTime: !endTime,
        purpose: !purpose,
        othersPurpose: purpose === SELECT_MODAL_OTHERS && !othersPurpose,
        firstName: !formatName(firstName),
        lastName: !formatName(lastName),
        mobileNumber: !mobileNumber || mobileNumber.length !== 10
      };

      setErrors(newErrors);

      if (Object.values(newErrors).some((error) => error)) {
        displayToast({
          message: 'Please fill in all required fields.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'createRequestButton'
        });
        return;
      }

      if (!dentist?.id) {
        setErrors((prevErrors) => ({ ...prevErrors, dentist: true }));
        displayToast({
          message: 'Please select a dentist.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'createRequestButton'
        });
        return;
      }

      setLoading(true);

      const newPatient: Patient = {
        first_name: formatName(firstName),
        last_name: formatName(lastName),
        mobile_number: mobileNumber,
        gender: gender as Gender | null,
        birthday: birthday,
        dentist_id: dentist.id
      };

      const createdPatient = await createPatient(newPatient);

      if (!createdPatient?.id) {
        setLoading(false);
        displayToast({
          message:
            'Failed to create patient. Please check your internet connection and try again.',
          duration: 3000,
          position: 'bottom'
        });
        return;
      }

      const newRequest: Request = {
        date: date || null,
        start_time: startTime || null,
        end_time: endTime || null,
        purpose:
          purpose === SELECT_MODAL_OTHERS
            ? `${purpose}: ${othersPurpose}`
            : purpose,
        status: REQUEST_STATUS_OPTIONS.APPROVED,
        dentist_id: dentist.id,
        patient_id: createdPatient.id
      };

      const createdRequest = await createRequest(newRequest);

      if (createdRequest) {
        setRequests([
          ...requests,
          { ...createdRequest, patient: createdPatient }
        ]);
        captureEvent({
          event: 'manual booking created',
          properties: { request_id: createdRequest.id! }
        });
        setLoading(false);
        reset();
        if (onClose) {
          onClose();
        }
        if (setSelectedDate) {
          setSelectedDate(moment(date).startOf('day').toDate());
        }
        if (createdRequest.id) {
          setFocusedRequestId(createdRequest.id);
        }
      }
    } catch {
      setLoading(false);
      displayToast({
        message:
          'Failed to create appointment. Please check your internet connection and try again.',
        duration: 3000,
        position: 'bottom',
        positionAnchor: 'createRequestButton'
      });
    }
  }, [
    date,
    startTime,
    endTime,
    purpose,
    othersPurpose,
    firstName,
    lastName,
    mobileNumber,
    gender,
    birthday,
    dentist,
    requests,
    setRequests,
    displayToast,
    reset,
    onClose,
    setSelectedDate,
    setFocusedRequestId
  ]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLIonInputElement>) => {
      if (event.key === 'Enter') {
        submit();
      }
    },
    [submit]
  );

  useEffect(() => {
    if (selectedDate && moment(selectedDate).isValid()) {
      setDate(moment(selectedDate).startOf('day').format('YYYY-MM-DD'));
    } else {
      setDate(null);
    }
  }, [selectedDate]);

  useEffect(() => {
    return () => {
      setDate(null);
      setStartTime(null);
      setEndTime(null);
      setPurpose(null);
      setOthersPurpose(null);
      setFirstName(null);
      setLastName(null);
      setMobileNumber(null);
      setGender(null);
      setBirthday(null);
      setPurposeOptions([]);
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    const fetchServices = async () => {
      if (dentist?.id) {
        try {
          const services = await getServicesByDentistId(dentist.id);
          if (services.length > 0) {
            setPurposeOptions(services.map((service) => service.name));
          } else {
            const defaultServices = await getDefaultServices();
            setPurposeOptions(defaultServices.map((service) => service.name));
          }
        } catch {
          displayToast({
            message: `Please check your internet connection.`,
            duration: 3000,
            position: 'bottom'
          });
        }
      }
    };

    if (isOpen) fetchServices();
  }, [dentist?.id, isOpen]);

  useEffect(() => {
    const patients = requests
      .map((request) => request.patient)
      .filter((patient) => !!patient)
      .filter((patient) => patient.id !== OFF_DUTY_PATIENT_ID)
      .reduce((uniquePatients: Patient[], patient: Patient) => {
        const normalizeName = (name: string | null | undefined) =>
          name?.toLowerCase()?.trim();

        if (
          !uniquePatients.some(
            (p) =>
              normalizeName(p.first_name) ===
                normalizeName(patient.first_name) &&
              normalizeName(p.last_name) === normalizeName(patient.last_name) &&
              p.mobile_number === patient.mobile_number
          )
        ) {
          uniquePatients.push(patient);
        }
        return uniquePatients;
      }, []);

    setPatients(patients);
  }, [requests]);

  useEffect(() => {
    const checkConflictingRequest = () => {
      const slotStartTime = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm');
      const slotEndTime = moment(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm');

      const conflict = requests
        .filter(
          (request) =>
            moment(date).isSame(moment(request.date, 'YYYY-MM-DD'), 'day') &&
            request.status === REQUEST_STATUS_OPTIONS.APPROVED
        )
        .find((request) => {
          const requestStartTime = moment(
            `${request.date} ${request.start_time}`,
            'YYYY-MM-DD HH:mm'
          );
          const requestEndTime = moment(
            `${request.date} ${request.end_time}`,
            'YYYY-MM-DD HH:mm'
          );

          return (
            slotStartTime.isBetween(
              requestStartTime,
              requestEndTime,
              null,
              '[)'
            ) ||
            slotEndTime.isBetween(
              requestStartTime,
              requestEndTime,
              null,
              '(]'
            ) ||
            requestStartTime.isBetween(
              slotStartTime,
              slotEndTime,
              null,
              '[)'
            ) ||
            requestEndTime.isBetween(slotStartTime, slotEndTime, null, '(]')
          );
        });

      setConflictingRequest(conflict ?? null);
    };

    checkConflictingRequest();
  }, [date, startTime, endTime, requests.length]);

  // Purpose Selection

  const isPurposeSelected = (value: string) =>
    purpose?.split(PURPOSE_JOIN_SEPARATOR).includes(value) || false;

  const isPurposePreSelected = (value: string) =>
    lastSavedPurpose?.split(PURPOSE_JOIN_SEPARATOR).includes(value) || false;

  const onSelectPurpose = (value: string, isSelected: boolean) => {
    if (purpose) {
      if (isSelected) {
        setPurpose(
          purpose
            .split(',')
            .map((item) => item.trim())
            .filter((item) => item !== value)
            .join(PURPOSE_JOIN_SEPARATOR)
        );
      } else {
        setPurpose(purpose + PURPOSE_JOIN_SEPARATOR + value);
      }
    } else {
      setPurpose(value);
    }
  };

  const onClosePurposeSelectModal = () => {
    setIsPurposeSelectModalOpen(false);
    setLastSavedPurpose(purpose);
  };

  // Patient Search

  const onClosePatientSearchModal = () => {
    setIsPatientSearchModalOpen(false);
  };

  const onPressPatient = async (patient: Patient) => {
    const formattedFirstName = formatName(patient.first_name);
    const formattedLastName = formatName(patient.last_name);

    const { value: confirmed } = await Dialog.confirm({
      title: 'Confirm',
      message: `Are you sure you want to book an appointment for ${formattedFirstName} ${formattedLastName}?`,
      okButtonTitle: 'Yes'
    });

    if (!confirmed) return;

    setFirstName(formattedFirstName);
    setLastName(formattedLastName);
    setErrors((prevErrors) => ({
      ...prevErrors,
      firstName: false,
      lastName: false
    }));
    if (patient.mobile_number) {
      setMobileNumber(patient.mobile_number);
      setErrors((prevErrors) => ({ ...prevErrors, mobileNumber: false }));
    }
    if (patient.gender) {
      setGender(patient.gender);
    }
    if (patient.birthday) {
      setBirthday(patient.birthday);
    }

    onClosePatientSearchModal();
  };

  return (
    <ManualBookingContext.Provider
      value={{
        // Date and Time
        date,
        setDate,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        timeOptions,

        // Purpose
        purpose,
        setPurpose,
        othersPurpose,
        setOthersPurpose,
        purposeOptions,
        setPurposeOptions,
        isPurposeSelected,
        isPurposePreSelected,
        onSelectPurpose,
        isPurposeSelectModalOpen,
        setIsPurposeSelectModalOpen,
        onClosePurposeSelectModal,

        // Patient Search
        patients,
        setPatients,
        isPatientSearchModalOpen,
        setIsPatientSearchModalOpen,
        onPressPatient,
        onClosePatientSearchModal,

        // Patient Information
        firstName,
        setFirstName,
        lastName,
        setLastName,
        mobileNumber,
        setMobileNumber,
        gender,
        setGender,
        birthday,
        setBirthday,

        // Miscellaneous
        errors,
        setErrors,
        loading,
        setLoading,
        onInput,
        submit,
        reset,
        onKeyDown,
        conflictingRequest,
        inputRef
      }}
    >
      {children}
    </ManualBookingContext.Provider>
  );
};

export const useManualBooking = () => {
  const context = useContext(ManualBookingContext);
  if (context === undefined) {
    throw new Error(
      'useManualBooking must be used within a ManualBookingProvider'
    );
  }
  return context;
};
