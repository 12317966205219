import {
  IonButton,
  IonContent,
  IonInput,
  IonLoading,
  IonModal,
  IonRouterLink,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Logo from '@/components/common/logo/Logo';
import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import Icon from '@/components/memo/icon/Icon';
import Text from '@/components/memo/text/Text';
import SearchModal from '@/components/modals/searchModal/SearchModal';
import SelectModal from '@/components/modals/selectModal/SelectModal';
import {
  ManualBookingProvider,
  useManualBooking
} from '@/contexts/ManualBookingContext';
import { PATIENT_FIELDS, Patient } from '@/data/Patients';
import { SELECT_MODAL_OTHERS } from '@/utils/constants';
import { computeAge } from '@/utils/helpers/computeAge';
import { formatName } from '@/utils/helpers/formatName';
import { getGenderIcon } from '@/utils/helpers/getGenderIcon';
import { arrowBackOutline, searchOutline, warning } from 'ionicons/icons';
import moment from 'moment';

import './ManualBooking.css';

interface ManualBookingProps {
  isOpen: boolean;
  onClose: () => void;
  selectedDate?: Date | string | null | undefined;
  setSelectedDate?: (date: Date) => void;
  presentingElement?: HTMLElement | undefined;
}

const ManualBooking: React.FC<ManualBookingProps> = ({
  isOpen,
  onClose,
  selectedDate: _,
  presentingElement
}) => {
  const {
    // Date and Time
    date,
    startTime,
    endTime,
    timeOptions,

    // Purpose
    purpose,
    othersPurpose,
    purposeOptions,
    isPurposeSelected,
    isPurposePreSelected,
    onSelectPurpose,
    isPurposeSelectModalOpen,
    setIsPurposeSelectModalOpen,
    onClosePurposeSelectModal,

    // Patient Search
    patients,
    isPatientSearchModalOpen,
    setIsPatientSearchModalOpen,
    onPressPatient,
    onClosePatientSearchModal,

    // Patient Information
    firstName,
    lastName,
    mobileNumber,
    gender,
    birthday,

    // Miscellaneous
    loading,
    errors,
    setErrors,
    onInput,
    submit,
    reset,
    onKeyDown,
    conflictingRequest,
    inputRef
  } = useManualBooking();

  const modal = useRef<HTMLIonModalElement | null>(null);

  const [selectModalPresentingElement, setSelectModalPresentingElement] =
    useState<HTMLElement | undefined>(undefined);

  useEffect(() => {
    setSelectModalPresentingElement(modal.current ?? undefined);
  }, [modal.current]);

  const copy = {
    subHeader: 'NEW APPOINTMENT',
    header: (
      <>
        Create a new <Text color="primary">appointment</Text>
      </>
    ),
    message: 'Please fill in the details below to create a new appointment.',
    selectPurposeModalTitle: (
      <>
        Select <Text color="primary">purpose</Text>
      </>
    ),
    searchPatientModalTitle: (
      <>
        Search <Text color="primary">patients</Text>
      </>
    ),
    searchPatientModalPlaceholder: 'Search patient name or mobile number',
    othersLabel: 'Others, please specify...',
    othersPlaceholder: 'Enter purpose (optional)',
    datePlaceholder: 'Select a date',
    startTimePlaceholder: 'Select a start time',
    endTimePlaceholder: 'Select an end time',
    purposePlaceholder: 'Example: Braces Adjustment',
    firstNamePlaceholder: "Enter patient's first name",
    lastNamePlaceholder: "Enter patient's last name",
    mobileNumberPlaceholder: "Enter patient's mobile number",
    genderPlaceholder: "Select patient's gender",
    birthdayPlaceholder: "Enter patient's birthday",
    countryCode: '+63',
    genders: {
      male: 'Male',
      female: 'Female',
      other: 'Prefer not to say'
    },
    patientInformation: 'PATIENT INFORMATION'
  };

  const getPatientLabel = (patient: Patient) => {
    const computedAge = computeAge(patient.birthday);
    const patientAge = computedAge > 0 ? `${computedAge} years old` : '';

    return (
      <div className="patientLabel">
        <div className="patientNameAndGender">
          <p className="patientName">
            {formatName(patient.first_name)}{' '}
            <Text color="primary">{formatName(patient.last_name)}</Text>
          </p>
          <Icon
            icon={getGenderIcon(patient.gender)}
            color="primary"
            className="patientGenderIcon"
          />
        </div>

        <div className="patientAge">{patientAge}</div>
      </div>
    );
  };

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={() => {
        reset();
        onClose();
      }}
      presentingElement={presentingElement}
      ref={modal}
    >
      <IonContent>
        <Padding withSafeArea={!presentingElement}>
          <div id="manualBooking">
            <Logo
              leftIcon={arrowBackOutline}
              onClickLeftIcon={() => {
                reset();
                modal.current?.dismiss();
              }}
            />
            <Spacer top={0} bottom={24}>
              <h4 id="subHeader">{copy.subHeader}</h4>
              <h1 id="header">{copy.header}</h1>
              <p id="message">{copy.message}</p>
            </Spacer>

            <Spacer bottom={124}>
              <div id="form">
                <div className={`input ${errors.date ? 'error' : ''}`}>
                  <IonInput
                    ref={inputRef}
                    label="Date"
                    labelPlacement="stacked"
                    value={date}
                    type="date"
                    placeholder={copy.datePlaceholder}
                    onIonInput={(e: CustomEvent) =>
                      onInput('date', e.detail.value!)
                    }
                    onKeyDown={onKeyDown}
                    min={moment().format('YYYY-MM-DD')}
                    max={
                      moment().endOf('year').isAfter(moment().add(3, 'months'))
                        ? moment().endOf('year').format('YYYY-MM-DD')
                        : moment()
                            .add(3, 'months')
                            .endOf('year')
                            .format('YYYY-MM-DD')
                    }
                  />
                </div>
                <div className={`input ${errors.startTime ? 'error' : ''}`}>
                  <IonSelect
                    label="Start Time"
                    labelPlacement="stacked"
                    value={startTime}
                    compareWith={(a: string, b: string) =>
                      moment(a, 'HH:mm').isSame(moment(b, 'HH:mm'))
                    }
                    placeholder={copy.startTimePlaceholder}
                    onIonChange={(e: CustomEvent) =>
                      onInput('startTime', e.detail.value!)
                    }
                  >
                    {timeOptions.slice(0, -1).map((time) => (
                      <IonSelectOption key={time} value={time}>
                        {moment(time, 'HH:mm').format('h:mm A')}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </div>
                <div className={`input ${errors.endTime ? 'error' : ''}`}>
                  <IonSelect
                    label="End Time"
                    labelPlacement="stacked"
                    value={endTime}
                    compareWith={(a: string, b: string) =>
                      moment(a, 'HH:mm').isSame(moment(b, 'HH:mm'))
                    }
                    placeholder={copy.endTimePlaceholder}
                    onIonChange={(e: CustomEvent) =>
                      onInput('endTime', e.detail.value!)
                    }
                  >
                    {timeOptions
                      .filter(
                        (time) =>
                          !startTime ||
                          moment(time, 'HH:mm').isAfter(
                            moment(startTime, 'HH:mm')
                          )
                      )
                      .map((time) => (
                        <IonSelectOption key={time} value={time}>
                          {moment(time, 'HH:mm').format('h:mm A')}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </div>
                {conflictingRequest && (
                  <div className="conflictWarning">
                    <Icon icon={warning} />
                    <Text color="warning">
                      <strong>Caution:</strong> The selected timeslot overlaps
                      with an existing appointment. You may continue, but ensure
                      this is intentional.
                    </Text>
                  </div>
                )}
                <div className={`input ${errors.purpose ? 'error' : ''}`}>
                  <IonInput
                    id="purposeInput"
                    label="Purpose"
                    labelPlacement="stacked"
                    value={purpose}
                    placeholder={copy.purposePlaceholder}
                    onIonInput={(e: CustomEvent) =>
                      onInput('purpose', e.detail.value!)
                    }
                    onKeyDown={onKeyDown}
                    onClick={() => {
                      setErrors({ ...errors, purpose: false });
                      setIsPurposeSelectModalOpen(true);
                    }}
                    readonly
                  />
                </div>
                {purpose === SELECT_MODAL_OTHERS && (
                  <div
                    className={`input ${errors.othersPurpose ? 'error' : ''}`}
                  >
                    <IonInput
                      id="othersInput"
                      label={copy.othersLabel}
                      labelPlacement="stacked"
                      value={othersPurpose}
                      placeholder={copy.othersPlaceholder}
                      onIonInput={(event: CustomEvent) =>
                        onInput('othersPurpose', event.detail.value!)
                      }
                      onKeyDown={onKeyDown}
                    />
                  </div>
                )}
                <div className="formDivider">
                  <p className="formDividerText">
                    <Text>{copy.patientInformation}</Text>
                  </p>
                  <div className="formDividerLine" />
                </div>
                <div className="searchExistingPatientsLinkContainer">
                  <Icon
                    className="searchExistingPatientsIcon"
                    icon={searchOutline}
                    color="primary"
                    onClick={() => setIsPatientSearchModalOpen(true)}
                  />
                  <IonRouterLink
                    className="searchExistingPatientsLink"
                    onClick={() => setIsPatientSearchModalOpen(true)}
                  >
                    Search existing patients
                  </IonRouterLink>
                </div>
                <div className={`input ${errors.firstName ? 'error' : ''}`}>
                  <IonInput
                    label="First Name"
                    labelPlacement="stacked"
                    value={firstName}
                    placeholder={copy.firstNamePlaceholder}
                    onIonInput={(e: CustomEvent) =>
                      onInput('firstName', e.detail.value!)
                    }
                    onKeyDown={onKeyDown}
                  />
                </div>
                <div className={`input ${errors.lastName ? 'error' : ''}`}>
                  <IonInput
                    label="Last Name"
                    labelPlacement="stacked"
                    value={lastName}
                    placeholder={copy.lastNamePlaceholder}
                    onIonInput={(e: CustomEvent) =>
                      onInput('lastName', e.detail.value!)
                    }
                    onKeyDown={onKeyDown}
                  />
                </div>
                <div className={`input ${errors.mobileNumber ? 'error' : ''}`}>
                  <IonInput
                    type="tel"
                    label="Mobile Number"
                    labelPlacement="stacked"
                    value={mobileNumber}
                    placeholder={copy.mobileNumberPlaceholder}
                    onIonInput={(e: CustomEvent) =>
                      onInput('mobileNumber', e.detail.value!)
                    }
                    onKeyDown={onKeyDown}
                    maxlength={10}
                  >
                    <div slot="start">{copy.countryCode}</div>
                  </IonInput>
                </div>
                <div className={`input ${errors.gender ? 'error' : ''}`}>
                  <IonSelect
                    label="Gender (optional)"
                    labelPlacement="stacked"
                    value={gender}
                    placeholder={copy.genderPlaceholder}
                    onIonChange={(e: CustomEvent) =>
                      onInput('gender', e.detail.value!)
                    }
                  >
                    <IonSelectOption value="male">
                      {copy.genders.male}
                    </IonSelectOption>
                    <IonSelectOption value="female">
                      {copy.genders.female}
                    </IonSelectOption>
                    <IonSelectOption value="other">
                      {copy.genders.other}
                    </IonSelectOption>
                  </IonSelect>
                </div>
                <div className={`input ${errors.birthday ? 'error' : ''}`}>
                  <IonInput
                    label="Birthday (optional)"
                    labelPlacement="stacked"
                    value={birthday}
                    type="date"
                    placeholder={copy.birthdayPlaceholder}
                    onIonInput={(e: CustomEvent) =>
                      onInput('birthday', e.detail.value!)
                    }
                    onKeyDown={onKeyDown}
                  />
                </div>
              </div>
            </Spacer>

            <ButtonContainer>
              <IonButton
                id="createRequestButton"
                onClick={submit}
                expand="block"
                fill="solid"
                size="default"
                color="primary"
                strong={true}
              >
                Create Appointment
              </IonButton>
            </ButtonContainer>
          </div>
        </Padding>
        <IonLoading isOpen={loading} message="Loading..." spinner="crescent" />

        <SelectModal
          isOpen={isPurposeSelectModalOpen}
          presentingElement={selectModalPresentingElement}
          title={copy.selectPurposeModalTitle}
          options={purposeOptions}
          isSelected={isPurposeSelected}
          isPreSelected={isPurposePreSelected}
          onSelect={onSelectPurpose}
          onClose={onClosePurposeSelectModal}
          multiple={true}
        />

        <SearchModal
          isOpen={isPatientSearchModalOpen}
          presentingElement={selectModalPresentingElement}
          title={copy.searchPatientModalTitle}
          searchPlaceholder={copy.searchPatientModalPlaceholder}
          data={patients}
          getLabel={getPatientLabel}
          onPress={onPressPatient}
          onClose={onClosePatientSearchModal}
          searchFields={[
            PATIENT_FIELDS.FIRST_NAME,
            PATIENT_FIELDS.LAST_NAME,
            PATIENT_FIELDS.MOBILE_NUMBER
          ]}
          sortBy={PATIENT_FIELDS.FIRST_NAME}
        />
      </IonContent>
    </IonModal>
  );
};

const ManualBookingWithProvider: React.FC<ManualBookingProps> = (props) => {
  return (
    <ManualBookingProvider {...props}>
      <ManualBooking {...props} />
    </ManualBookingProvider>
  );
};

export default ManualBookingWithProvider;
