import React, { useEffect, useState } from 'react';

import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

import useDimensions from '@/hooks/useDimensions';

import './ButtonContainer.css';

interface ButtonContainerProps {
  position?: 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky';
  hideOnKeyboardOpen?: boolean;
  children: React.ReactNode;
  translucent?: boolean;
}

const ButtonContainer: React.FC<ButtonContainerProps> = ({
  position = 'fixed',
  hideOnKeyboardOpen = true,
  children,
  translucent = true
}) => {
  const { height } = useDimensions();

  const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>(false);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener('keyboardWillShow', (_) => {
        setIsKeyboardOpen(true);
      });

      Keyboard.addListener('keyboardWillHide', () => {
        setIsKeyboardOpen(false);
      });
    } else {
      window.addEventListener('keyboardWillShow', () => {
        setIsKeyboardOpen(true);
      });

      window.addEventListener('keyboardWillHide', () => {
        setIsKeyboardOpen(false);
      });
    }

    return () => {
      if (Capacitor.isNativePlatform()) {
        Keyboard.removeAllListeners();
      } else {
        window.removeEventListener('keyboardWillShow', () => {});
        window.removeEventListener('keyboardWillHide', () => {});
      }
    };
  }, []);

  if (hideOnKeyboardOpen && isKeyboardOpen && height && height <= 800) {
    return null;
  }

  return (
    <div
      id="buttonContainer"
      className={`ion-padding ${translucent ? 'translucent' : ''}`}
      style={{ position }}
    >
      {children}
    </div>
  );
};

export default ButtonContainer;
