import { IonContent, IonPage } from '@ionic/react';

import logo from '@/assets/images/logo/logo-with-icon.png';
import Padding from '@/components/common/padding/Padding';
import Promo from '@/components/common/promo/Promo';
import Text from '@/components/memo/text/Text';

import './NoInternet.css';

const NoInternet: React.FC = () => {
  const copy = {
    promo: {
      header: (
        <>
          No <Text color="primary">internet</Text> connection!
        </>
      ),
      subHeader: 'Please check your connection and try again.'
    }
  };

  return (
    <IonPage id="noInternet">
      <IonContent>
        <Padding>
          <div id="promoContainer">
            <Promo
              image={logo}
              imageStyle={{ maxWidth: 192 }}
              header={copy.promo.header}
              subHeader={copy.promo.subHeader}
            />
          </div>
        </Padding>
      </IonContent>
    </IonPage>
  );
};

export default NoInternet;
