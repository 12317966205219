import { IonButton, IonContent, IonPage } from '@ionic/react';

import { Capacitor } from '@capacitor/core';

import logo from '@/assets/images/logo/logo-with-icon.png';
import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Padding from '@/components/common/padding/Padding';
import Promo from '@/components/common/promo/Promo';
import Text from '@/components/memo/text/Text';
import useToast from '@/hooks/useToast';
import { APP_STORE_ID, PLATFORMS } from '@/utils/constants';
import { AppUpdate } from '@capawesome/capacitor-app-update';

import './Update.css';

const Update: React.FC = () => {
  const displayToast = useToast();

  const openAppStore = async () => {
    try {
      if (Capacitor.getPlatform() === PLATFORMS.IOS) {
        await AppUpdate.openAppStore({ appId: APP_STORE_ID, country: 'PH' });
      } else {
        await AppUpdate.openAppStore();
      }
    } catch {
      displayToast({
        message: `There was an error opening the App Store, please update manually.`,
        duration: 5000,
        position: 'bottom'
      });
    }
  };

  const copy = {
    promo: {
      header: (
        <>
          Time for an <Text color="primary">update!</Text>
        </>
      ),
      subHeader:
        'You need to update your version of the Odonto App to continue.'
    },
    updateButtonText: "Let's go"
  };

  return (
    <IonPage id="update">
      <IonContent>
        <Padding>
          <div id="promoContainer">
            <Promo
              image={logo}
              imageStyle={{ maxWidth: 192 }}
              header={copy.promo.header}
              subHeader={copy.promo.subHeader}
            />
          </div>
          <ButtonContainer>
            <IonButton
              id="updateButton"
              onClick={openAppStore}
              expand="block"
              fill="solid"
              size="default"
              color="primary"
              strong={true}
            >
              {copy.updateButtonText}
            </IonButton>
          </ButtonContainer>
        </Padding>
      </IonContent>
    </IonPage>
  );
};

export default Update;
