import {
  IonContent,
  IonHeader,
  IonModal,
  IonRouterLink,
  IonSearchbar
} from '@ionic/react';
import React, { useEffect, useState } from 'react';

import Empty from '@/components/common/empty/Empty';
import { DEFAULT_FIELDS, SORT_DIRECTIONS } from '@/utils/constants';
import { SortDirection } from '@/utils/types';

import './SearchModal.css';

interface SearchModalProps {
  isOpen: boolean;
  presentingElement?: HTMLElement | undefined;
  title?: string | React.ReactNode;
  searchPlaceholder?: string;
  data: any[];
  getLabel?: (data: any) => string | React.ReactNode;
  searchFields?: string[];
  sortBy?: string;
  sortDirection?: SortDirection;
  onPress: (data: any) => void;
  onClose: () => void;
}

const SearchModal: React.FC<SearchModalProps> = ({
  isOpen,
  presentingElement,
  title = 'Search',
  searchPlaceholder = 'Search',
  data,
  getLabel = (data: any) => data,
  searchFields = [DEFAULT_FIELDS.ID],
  sortBy = DEFAULT_FIELDS.ID,
  sortDirection = SORT_DIRECTIONS.ASC,
  onPress,
  onClose
}) => {
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [filteredData, setFilteredData] = useState<any[]>(data);

  const filterData = (searchQuery: string | null | undefined) => {
    if (!searchQuery) {
      setFilteredData(data);
    } else {
      const normalizedQuery = searchQuery.startsWith('0')
        ? searchQuery.slice(1).toLowerCase()
        : searchQuery.toLowerCase();

      if (normalizedQuery.length === 0) {
        setFilteredData(data);
      } else {
        setFilteredData(
          data.filter((data) =>
            searchFields.some(
              (field) =>
                data.hasOwnProperty(field) &&
                data[field].toString().toLowerCase().includes(normalizedQuery)
            )
          )
        );
      }
    }
  };

  useEffect(() => {
    filterData(searchQuery);
  }, [searchQuery, data]);

  const emptyMessage = `No results found${searchQuery ? ` for "${searchQuery}"` : ''}`;

  const onSearch = (event: CustomEvent) => {
    setSearchQuery(event.detail.value);
  };

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onClose}
      presentingElement={presentingElement}
    >
      <IonHeader
        className={`searchModalHeader ${presentingElement ? '' : 'withSafeArea'}`}
      >
        <h1 className="title">{title}</h1>
        <div className="searchBar">
          <IonSearchbar
            onIonInput={onSearch}
            autoFocus={false}
            debounce={324}
            placeholder={searchPlaceholder ?? 'Search'}
          ></IonSearchbar>
          <IonRouterLink onClick={onClose}>Done</IonRouterLink>
        </div>
      </IonHeader>
      <IonContent className="searchModalContent ion-padding">
        <div className="data">
          {filteredData
            .sort((a, b) => {
              if (a.hasOwnProperty(sortBy) && b.hasOwnProperty(sortBy)) {
                if (
                  typeof a[sortBy] === 'number' &&
                  typeof b[sortBy] === 'number'
                ) {
                  return sortDirection === SORT_DIRECTIONS.ASC
                    ? a[sortBy] - b[sortBy]
                    : b[sortBy] - a[sortBy];
                } else if (
                  typeof a[sortBy] === 'string' &&
                  typeof b[sortBy] === 'string'
                ) {
                  return sortDirection === SORT_DIRECTIONS.ASC
                    ? a[sortBy].localeCompare(b[sortBy])
                    : b[sortBy].localeCompare(a[sortBy]);
                }
                return 0;
              }
              return 0;
            })
            .map((data, index) => {
              return (
                <div
                  key={`searchModalData-${index}`}
                  className="dataItem"
                  onClick={() => onPress(data)}
                >
                  <div className="dataLabel">{getLabel(data)}</div>
                </div>
              );
            })}
        </div>
        {filteredData.length === 0 && <Empty message={emptyMessage} />}
      </IonContent>
    </IonModal>
  );
};

export default SearchModal;
