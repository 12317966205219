import React, { useEffect, useRef } from 'react';

import Spacer from '@/components/common/spacer/Spacer';
import Icon from '@/components/memo/icon/Icon';
import Text from '@/components/memo/text/Text';
import { Request } from '@/data/Requests';
import {
  ATTENDANCE_STATUS_OPTIONS,
  GENDER_LABELS,
  OFF_DUTY_PATIENT_ID
} from '@/utils/constants';
import { formatMobileNumber } from '@/utils/helpers/formatMobileNumber';
import { formatName } from '@/utils/helpers/formatName';
import { GenderLabelKeys } from '@/utils/types';
import {
  alertCircleOutline,
  bodyOutline,
  callOutline,
  maleFemaleOutline
} from 'ionicons/icons';
import moment from 'moment';

import './TimelineItem.css';

interface TimelineItemProps {
  request: Request;
  onClick: (request: Request, isPast?: boolean) => void;
  isLast: boolean;
  isDifferentTime: boolean;
  isOverlappedStartTime: boolean;
  focusedRequestId?: string;
}

const TimelineItem: React.FC<TimelineItemProps> = ({
  request,
  onClick,
  isLast,
  isDifferentTime,
  isOverlappedStartTime,
  focusedRequestId
}) => {
  const timelineItemRef = useRef<HTMLDivElement>(null);

  const formatTime = (time: string | null) =>
    moment(time, 'HH:mm').format('h:mm A');

  const showDetails =
    request.patient?.gender ||
    request.patient?.birthday ||
    request.patient?.mobile_number;

  const isPast = moment(
    `${request.date} ${request.end_time}`,
    'YYYY-MM-DD HH:mm'
  ).isSameOrBefore(moment());

  const showAttendanceStatus =
    isPast &&
    request.attendance_status &&
    request.attendance_status !== ATTENDANCE_STATUS_OPTIONS.DEFAULT;

  const isOffDuty = request.patient_id === OFF_DUTY_PATIENT_ID;

  useEffect(() => {
    if (focusedRequestId === request.id) {
      timelineItemRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [focusedRequestId, request.id, timelineItemRef.current]);

  return (
    <React.Fragment>
      <div
        className={`timelineItem ${isPast ? 'past' : ''}`}
        ref={timelineItemRef}
      >
        <div className="timelineTime">
          <div
            className={`timelineCircle ${isOverlappedStartTime ? 'overlappedStartTime' : ''}`}
          >
            <p>{formatTime(request.start_time)}</p>
          </div>
          <div className="timelineLine"></div>
        </div>
        <div
          className="timelineContent"
          onClick={() => onClick(request, isPast)}
        >
          <p className="timelinePatient">
            {formatName(request.patient?.first_name)}
            {isOffDuty ? '-' : ' '}
            <Text color="primary">
              {formatName(request.patient?.last_name)}
            </Text>
          </p>
          <Spacer top={2} bottom={showDetails ? 8 : 0}>
            <p className="timelinePurpose">{request.purpose}</p>
          </Spacer>
          {showDetails && (
            <div className="timelineDetails">
              {request.patient?.gender && (
                <p className="timelineGender">
                  <Icon icon={maleFemaleOutline} />{' '}
                  {GENDER_LABELS[
                    request.patient.gender.toUpperCase() as GenderLabelKeys
                  ] ?? GENDER_LABELS.OTHER}
                </p>
              )}
              {request.patient?.birthday && (
                <p className="timelineBirthday">
                  <Icon icon={bodyOutline} />{' '}
                  {moment().diff(moment(request.patient.birthday), 'years')}{' '}
                  years old
                </p>
              )}
              {request.patient?.mobile_number && (
                <p className="timelineMobileNumber">
                  <Icon icon={callOutline} />
                  {formatMobileNumber(request.patient.mobile_number)}
                </p>
              )}
            </div>
          )}
          {showAttendanceStatus && (
            <p className="timelineAttendanceStatus">
              <Icon icon={alertCircleOutline} />
              {request.attendance_status?.toUpperCase()}
            </p>
          )}
        </div>
      </div>
      {(isLast || isDifferentTime) && (
        <div className={`timelineItem ${isPast ? 'past' : ''}`}>
          <div className="timelineTime">
            <div className="timelineCircle endTime">
              <p>{formatTime(request.end_time)}</p>
            </div>
            {!isLast && <div className="timelineLine endTime"></div>}
          </div>
          <div className="timelineContent empty"></div>
        </div>
      )}
    </React.Fragment>
  );
};

export default TimelineItem;
