import { IonButton, IonInput, IonRouterLink } from '@ionic/react';
import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Spacer from '@/components/common/spacer/Spacer';
import Icon from '@/components/memo/icon/Icon';
import { useSignIn } from '@/contexts/SignInContext';
import { useInputFocus } from '@/hooks/useInputFocus';
import useToast from '@/hooks/useToast';
import { GET_STARTED_STEPS, OTP_LENGTH } from '@/utils/constants';
import { arrowForwardCircleOutline } from 'ionicons/icons';

import './Verification.css';

const Verification: React.FC = () => {
  const { mobileNumber, resendOtp, verifyOtp, setCurrentStep } = useSignIn();
  const displayToast = useToast();

  const [otp, setOtp] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(60);
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);

  const inputRef = useRef<HTMLIonInputElement>(null);

  useInputFocus(inputRef);

  const copy = {
    subHeader: 'VERIFY YOUR MOBILE NUMBER',
    header: (
      <>
        A verification <span>code</span> has been sent to:
      </>
    ),
    buttonText: 'Next'
  };

  const formattedMobileNumber = useMemo(
    () =>
      mobileNumber
        ? `0${mobileNumber.slice(0, 3)} ${mobileNumber.slice(3, 6)} ${mobileNumber.slice(6, 10)}`
        : '--',
    [mobileNumber]
  );

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setIsResendDisabled(false);
    }
  }, [timer]);

  const onInput = (event: CustomEvent) => {
    setOtp(event.detail.value!);
    setHasError(false);
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onNext();
    }
  };

  const onNext = useCallback(() => {
    if (otp.length === OTP_LENGTH) {
      setHasError(false);
      verifyOtp(otp);
    } else {
      setHasError(true);
      displayToast({
        message: 'Please enter a valid OTP.',
        duration: 5000,
        position: 'bottom',
        positionAnchor: 'nextButton'
      });
    }
  }, [otp, verifyOtp]);

  const onResend = useCallback(() => {
    if (!isResendDisabled) {
      resendOtp();
      setTimer(60);
      setIsResendDisabled(true);
    }
  }, [resendOtp, isResendDisabled]);

  return (
    <div id="verification">
      <Spacer top={32} bottom={24}>
        <h4 id="subHeader">{copy.subHeader}</h4>
        <h1 id="header">{copy.header}</h1>
      </Spacer>
      <div id="number">
        <h2 id="mobileNumber">{formattedMobileNumber}</h2>
        <IonRouterLink
          onClick={() => setCurrentStep(GET_STARTED_STEPS.MOBILE_NUMBER)}
          id="changeNumber"
        >
          Change Number
        </IonRouterLink>
      </div>

      <Spacer top={16} bottom={102}>
        <div id="form">
          <div className={`input ${hasError ? 'error' : ''}`}>
            <IonInput
              ref={inputRef}
              type="text"
              inputmode="numeric"
              label="Verification Code"
              labelPlacement="stacked"
              value={otp}
              placeholder="Enter verification code"
              onIonInput={onInput}
              onKeyDown={onKeyDown}
              maxlength={6}
              autoFocus={true}
            />
          </div>
          <p id="resendOtp">
            Didn't receive a code?{' '}
            <IonRouterLink
              onClick={onResend}
              className={isResendDisabled ? 'disabled' : ''}
              color={isResendDisabled ? 'medium' : 'primary'}
            >
              Resend {isResendDisabled && ` in ${timer}s`}
            </IonRouterLink>
          </p>
        </div>
      </Spacer>
      <ButtonContainer>
        <IonButton
          id="nextButton"
          onClick={onNext}
          expand="block"
          fill="solid"
          size="default"
          color="primary"
          strong={true}
        >
          <Icon slot="start" icon={arrowForwardCircleOutline} />
          {copy.buttonText}
        </IonButton>
      </ButtonContainer>
    </div>
  );
};

export default Verification;
