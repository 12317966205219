import React from 'react';

import Icon from '@/components/memo/icon/Icon';

import './WeeklyReportDataItems.css';

interface WeeklyReportDataItemProps {
  icon: string;
  title: string;
  value: string | number;
}

const WeeklyReportDataItem: React.FC<WeeklyReportDataItemProps> = React.memo(
  ({ icon, title, value }) => {
    return (
      <div className="weeklyReportsDataItem">
        <div className="weeklyReportsDataItemLabel">
          <Icon icon={icon} />
          <p className="weeklyReportsDataItemTitle">{title}</p>
        </div>
        <p className="weeklyReportsDataItemValue">{value}</p>
      </div>
    );
  }
);

interface WeeklyReportDataItemsProps {
  items: {
    icon: string;
    title: string;
    value: string | number;
  }[];
}

const WeeklyReportDataItems: React.FC<WeeklyReportDataItemsProps> = React.memo(
  ({ items }) => {
    return (
      <>
        {items.map((item, index) => (
          <WeeklyReportDataItem
            key={index}
            icon={item.icon}
            title={item.title}
            value={item.value}
          />
        ))}
      </>
    );
  }
);

export default WeeklyReportDataItems;
