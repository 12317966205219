import capitalize from "@/utils/helpers/capitalize";

/**
 * Converts a given string to camelCase.
 * @param {string} str - The string to be converted to camelCase.
 * @returns {string} - The camelCased string.
 */
const camelCase = (str: string): string => {
  return str
    .split(" ")
    .map((word, index) => index === 0 ? word.toLowerCase() : capitalize(word))
    .join("");
};

export default camelCase;
