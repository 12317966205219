import { IonButton, IonInput, IonLoading } from '@ionic/react';
import React, { KeyboardEvent, useCallback, useRef, useState } from 'react';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Spacer from '@/components/common/spacer/Spacer';
import Icon from '@/components/memo/icon/Icon';
import { useAddDentist } from '@/contexts/AddDentistContext';
import { useClinic } from '@/contexts/ClinicContext';
import { getClinicByMobileNumber } from '@/data/Clinics';
import { useInputFocus } from '@/hooks/useInputFocus';
import useToast from '@/hooks/useToast';
import { ADD_DENTIST_STEPS, MOBILE_NUMBER_LENGTH } from '@/utils/constants';
import { arrowForwardCircleOutline } from 'ionicons/icons';

import './MobileNumber.css';

const MobileNumber: React.FC = () => {
  const { mobileNumber, setMobileNumber, setCurrentStep } = useAddDentist();
  const { selectedClinic: clinic } = useClinic();
  const displayToast = useToast();
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLIonInputElement>(null);

  useInputFocus(inputRef);

  const copy = {
    subHeader: 'DENTIST MOBILE NUMBER',
    header: (
      <>
        What's your <span>mobile</span> number?
      </>
    ),
    message:
      "Enter the dentist's mobile number. They will use this to log in and access their profile.",
    buttonText: 'Next',
    countryCode: '+63'
  };

  const onInput = (event: CustomEvent) => {
    setMobileNumber(event.detail.value!);
    setHasError(false);
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onNext();
    }
  };

  const onNext = useCallback(async () => {
    if (
      mobileNumber &&
      mobileNumber.length === MOBILE_NUMBER_LENGTH &&
      /^\d+$/.test(mobileNumber)
    ) {
      setHasError(false);
      try {
        setLoading(true);
        const existingClinic = await getClinicByMobileNumber(mobileNumber);
        if (clinic && existingClinic && existingClinic.id !== clinic.id) {
          setLoading(false);
          displayToast({
            message:
              'This mobile number is already registered. Please use a different number.',
            duration: 3000,
            position: 'bottom',
            positionAnchor: 'nextButton'
          });
        } else {
          setLoading(false);
          setCurrentStep(ADD_DENTIST_STEPS.HOURS);
        }
      } catch {
        setLoading(false);
        displayToast({
          message: 'Something went wrong. Please try again.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'nextButton'
        });
      }
    } else {
      setHasError(true);
      setLoading(false);
      displayToast({
        message: 'Please enter a valid mobile number.',
        duration: 5000,
        position: 'bottom',
        positionAnchor: 'nextButton'
      });
    }
  }, [mobileNumber, setCurrentStep, setLoading]);

  return (
    <div id="mobileNumber">
      <Spacer top={32} bottom={24}>
        <h4 id="subHeader">{copy.subHeader}</h4>
        <h1 id="header">{copy.header}</h1>
        <p id="message">{copy.message}</p>
      </Spacer>

      <Spacer top={16} bottom={102}>
        <div id="form">
          <div className={`input ${hasError ? 'error' : ''}`}>
            <IonInput
              ref={inputRef}
              type="tel"
              label="Mobile Number"
              labelPlacement="stacked"
              value={mobileNumber}
              placeholder="9---------"
              onIonInput={onInput}
              onKeyDown={onKeyDown}
              maxlength={10}
            >
              <div slot="start">{copy.countryCode}</div>
            </IonInput>
          </div>
        </div>
      </Spacer>
      <ButtonContainer>
        <IonButton
          id="nextButton"
          onClick={onNext}
          disabled={loading}
          expand="block"
          fill="solid"
          size="default"
          color="primary"
          strong={true}
        >
          <Icon slot="start" icon={arrowForwardCircleOutline} />
          {copy.buttonText}
        </IonButton>
      </ButtonContainer>
      <IonLoading isOpen={loading} message="Loading..." spinner="crescent" />
    </div>
  );
};

export default MobileNumber;
