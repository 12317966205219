import { IonButton } from '@ionic/react';
import React from 'react';

import Icon from '@/components/memo/icon/Icon';
import { useClinic } from '@/contexts/ClinicContext';
import { useShare } from '@/contexts/ShareContext';
import { shareOutline } from 'ionicons/icons';

import './SharePrompt.css';

interface SharePromptProps {
  message?: string | null;
}

const SharePrompt: React.FC<SharePromptProps> = ({
  message = 'Send your booking link to patients to schedule appointments'
}) => {
  const { selectedClinic: clinic } = useClinic();
  const { onShare, share } = useShare();

  return (
    <div id="sharePrompt">
      <p id="message">{message}:</p>
      <div id="shareLinkContainer">
        <p id="shareLink">
          {share
            ? `odonto.ph/s/${share.code}`
            : `odonto.ph/schedule/${clinic?.id}`}
        </p>
        <IonButton id="shareButton" onClick={onShare} strong={true}>
          <div id="shareButtonContent">
            <Icon icon={shareOutline} />
            <p>Send</p>
          </div>
        </IonButton>
      </div>
    </div>
  );
};

export default SharePrompt;
