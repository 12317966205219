import { IonButton, useIonAlert } from '@ionic/react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { Dialog } from '@capacitor/dialog';

import Spacer from '@/components/common/spacer/Spacer';
import Icon from '@/components/memo/icon/Icon';
import Text from '@/components/memo/text/Text';
import { useClinic } from '@/contexts/ClinicContext';
import { useDentist } from '@/contexts/DentistContext';
import { useRequests } from '@/contexts/RequestsContext';
import {
  Request,
  approveRequest,
  cancelRequest,
  declineRequest
} from '@/data/Requests';
import useToast from '@/hooks/useToast';
import {
  ACCESS_TYPES,
  REQUEST_ACTIONS,
  REQUEST_ACTION_ICONS,
  REQUEST_RESCHEDULE_METHODS,
  REQUEST_STATUS_OPTIONS
} from '@/utils/constants';
import capitalize from '@/utils/helpers/capitalize';
import { formatMobileNumber } from '@/utils/helpers/formatMobileNumber';
import { formatName } from '@/utils/helpers/formatName';
import { getGenderIcon } from '@/utils/helpers/getGenderIcon';
import { RequestAction, RequestStatus } from '@/utils/types';
import { calendarClearOutline, callOutline, timeOutline } from 'ionicons/icons';
import moment from 'moment';

import './RequestItem.css';

interface RequestProps {
  request: Request;
  onUpdateStatus: (request: Request, status: RequestStatus) => void;
  setUpdating: React.Dispatch<React.SetStateAction<boolean>>;
  setRequestToReschedule: React.Dispatch<React.SetStateAction<Request | null>>;
}

const RequestItem: React.FC<RequestProps> = ({
  request,
  onUpdateStatus,
  setUpdating,
  setRequestToReschedule
}) => {
  const requestItemRef = useRef<HTMLDivElement>(null);

  const { patient, status, date, start_time, end_time, purpose } = request;
  const { selectedDentist: dentist } = useDentist();
  const { isClinic } = useClinic();
  const { focusedRequestId } = useRequests();

  const [presentAlert] = useIonAlert();
  const displayToast = useToast();

  const handleReschedule = useCallback(async () => {
    await presentAlert({
      header: 'Reschedule',
      message: `How would you like to reschedule your appointment with ${formatName(patient?.first_name)}?`,
      inputs: [
        {
          type: 'radio',
          label: 'Manually enter a new date and time',
          value: REQUEST_RESCHEDULE_METHODS.MANUAL,
          checked: true
        },
        {
          type: 'radio',
          label:
            'Cancel appointment and send a new booking link to your patient',
          value: REQUEST_RESCHEDULE_METHODS.AUTOMATIC
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Confirm',
          handler: async (selectedMethod: string) => {
            if (selectedMethod === REQUEST_RESCHEDULE_METHODS.MANUAL) {
              setRequestToReschedule(request);
            } else if (
              selectedMethod === REQUEST_RESCHEDULE_METHODS.AUTOMATIC
            ) {
              await handleAction(REQUEST_ACTIONS.CANCEL);
            }
          }
        }
      ]
    });
  }, [request.id, presentAlert, onUpdateStatus, patient?.first_name]);

  const handleAction = useCallback(
    async (action: RequestAction) => {
      try {
        if (action === REQUEST_ACTIONS.RESCHEDULE) {
          await handleReschedule();
          return;
        }

        const { value: confirmed } = await Dialog.confirm({
          title: 'Confirm',
          message: `Are you sure you want to ${action.toUpperCase()} ${
            action === REQUEST_ACTIONS.CANCEL
              ? 'your appointment with'
              : 'the request from'
          } ${formatName(patient?.first_name)}?`,
          okButtonTitle: 'Yes'
        });

        if (!confirmed) return;

        setUpdating(true);
        const actionMap = {
          [REQUEST_ACTIONS.APPROVE]: {
            requestFunc: approveRequest,
            status: REQUEST_STATUS_OPTIONS.APPROVED,
            message:
              'Request approved. Notifications and reminders have been sent to your patient.'
          },
          [REQUEST_ACTIONS.DECLINE]: {
            requestFunc: declineRequest,
            status: REQUEST_STATUS_OPTIONS.DECLINED,
            message:
              'Request declined. A new booking link has been sent to your patient for rescheduling.'
          },
          [REQUEST_ACTIONS.CANCEL]: {
            requestFunc: cancelRequest,
            status: REQUEST_STATUS_OPTIONS.CANCELLED,
            message:
              'Appointment cancelled. A new booking link has been sent to your patient for rescheduling.'
          }
        };

        const { requestFunc, status, message } = actionMap[action];
        if (
          action === REQUEST_ACTIONS.CANCEL ||
          action === REQUEST_ACTIONS.DECLINE
        ) {
          const { value: reason, cancelled } = await Dialog.prompt({
            title: `Reason for ${action}`,
            message: 'Please provide a reason or any additional notes',
            inputPlaceholder: 'Enter reason or notes (optional)'
          });
          if (cancelled) return;
          await requestFunc(request.id!, reason === '' ? null : reason);
        } else {
          await requestFunc(request.id!);
        }
        onUpdateStatus(request, status);
        displayToast({
          message,
          duration: 5000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      } catch {
        displayToast({
          message: 'Something went wrong. Please try again.',
          duration: 5000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      } finally {
        setUpdating(false);
      }
    },
    [request.id, onUpdateStatus, patient?.first_name, handleReschedule]
  );

  const renderActionButton = (
    action: RequestAction,
    color: string,
    fill: 'clear' | 'solid' = 'solid'
  ) => (
    <IonButton
      expand="block"
      onClick={() => handleAction(action)}
      color={color}
      fill={fill}
      strong={true}
    >
      <Icon slot="start" icon={REQUEST_ACTION_ICONS[action]} />
      {capitalize(action)}
    </IonButton>
  );

  const hasAccess = useMemo(() => {
    return isClinic || dentist?.access === ACCESS_TYPES.EDIT;
  }, [isClinic, dentist?.access]);

  useEffect(() => {
    if (focusedRequestId === request.id) {
      requestItemRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [focusedRequestId, request.id, requestItemRef.current]);

  return (
    <div className="requestItem" ref={requestItemRef}>
      <div className="requestDetails">
        <div className="patient">
          <p className="patientName">
            {formatName(patient?.first_name)}{' '}
            <Text color="primary">{formatName(patient?.last_name)}</Text>
          </p>
          {patient?.gender && (
            <Icon icon={getGenderIcon(patient.gender)} className="gender" />
          )}
        </div>
        <Spacer top={2} bottom={12}>
          <p className="purpose">{purpose}</p>
        </Spacer>
        <div className="schedule">
          <div className="date">
            <Icon icon={calendarClearOutline} />
            <p>{moment(date).format('MMMM D, YYYY')}</p>
          </div>
          <div className="time">
            <Icon icon={timeOutline} />
            <p>
              {moment(start_time, 'HH:mm').format('h:mm A')} -{' '}
              {moment(end_time, 'HH:mm').format('h:mm A')}
            </p>
          </div>
          {patient?.mobile_number && (
            <div className="mobileNumber">
              <Icon icon={callOutline} />
              <p>{formatMobileNumber(patient.mobile_number)}</p>
            </div>
          )}
        </div>
      </div>
      {hasAccess && status === REQUEST_STATUS_OPTIONS.PENDING && (
        <Spacer bottom={-8}>
          <div className="requestActions">
            {renderActionButton(REQUEST_ACTIONS.APPROVE, 'success')}
            {renderActionButton(REQUEST_ACTIONS.DECLINE, 'danger', 'clear')}
          </div>
        </Spacer>
      )}
      {hasAccess &&
        (status === REQUEST_STATUS_OPTIONS.APPROVED ||
          status === REQUEST_STATUS_OPTIONS.RESCHEDULED) &&
        moment().isBefore(moment(date + ' ' + start_time), 'minute') && (
          <Spacer bottom={-8}>
            <div className="requestActions">
              {renderActionButton(REQUEST_ACTIONS.RESCHEDULE, 'dark')}
              {renderActionButton(REQUEST_ACTIONS.CANCEL, 'danger', 'clear')}
            </div>
          </Spacer>
        )}
    </div>
  );
};

export default RequestItem;
