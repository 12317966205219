import { CLINIC_NAME_IDENTIFIERS } from '@/utils/constants';

export const isClinicName = (name: string | null | undefined): boolean => {
  if (!name) return false;

  if (name.length === 0) return false;

  const clinicNameIdentifiers = CLINIC_NAME_IDENTIFIERS.map((identifier) =>
    identifier.toLowerCase()
  );
  return clinicNameIdentifiers.some((identifier) =>
    name.toLowerCase().includes(identifier)
  );
};
