import React from 'react';

import Spacer from '@/components/common/spacer/Spacer';
import Skeleton from '@/components/memo/skeleton/Skeleton';

import './RequestPlaceholder.css';

const RequestPlaceholder: React.FC = () => {
  return (
    <div className="requestItem">
      <div className="requestDetails">
        <div className="patient">
          <Skeleton className="patientNameSkeleton" />
          <Skeleton className="patientGenderSkeleton" />
        </div>
        <Spacer top={2} bottom={12}>
          <Skeleton className="purposeSkeleton" />
        </Spacer>
        <div className="schedule">
          <div className="date">
            <Skeleton className="dateSkeletonIcon iconSkeleton" />
            <Skeleton className="dateSkeleton" />
          </div>
          <div className="time">
            <Skeleton className="timeSkeletonIcon iconSkeleton" />
            <Skeleton className="timeSkeleton" />
          </div>
          <div className="mobileNumber">
            <Skeleton className="mobileNumberSkeletonIcon iconSkeleton" />
            <Skeleton className="mobileNumberSkeleton" />
          </div>
        </div>
      </div>
      <Spacer bottom={-8}>
        <div className="requestActions">
          <Skeleton className="actionButtonSkeleton" />
          <div className="subButtonContainer">
            <Skeleton className="actionButtonSkeleton" />
          </div>
        </div>
      </Spacer>
    </div>
  );
};

export default RequestPlaceholder;
