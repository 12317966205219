import { IonRouterLink } from '@ionic/react';
import React, { useState } from 'react';

import Icon from '@/components/memo/icon/Icon';
import Text from '@/components/memo/text/Text';
import { bulbOutline } from 'ionicons/icons';

import './Tips.css';

interface TipsProps {
  content: React.ReactNode[];
  onHide?: () => void;
  onNext?: () => void;
  showNextButton?: boolean;
  showHideButton?: boolean;
}

const Tips: React.FC<TipsProps> = ({
  content = [],
  onHide,
  showNextButton = true,
  showHideButton = true
}) => {
  const [currentTipIndex, setCurrentTipIndex] = useState<number>(0);

  const onNext = () => {
    setCurrentTipIndex((prevIndex) => (prevIndex + 1) % content.length);
  };

  return (
    <div className="tip">
      <div className="tipContent">
        <div className="tipIcon">
          <Icon icon={bulbOutline} />
        </div>
        <div className="tipText">
          <Text>Tip:</Text> {content[currentTipIndex]}
        </div>
      </div>
      {(showHideButton || showNextButton) && (
        <div className="tipAction">
          {showHideButton && (
            <IonRouterLink onClick={onHide}>Do not show again</IonRouterLink>
          )}
          {showNextButton && (
            <IonRouterLink onClick={onNext}>Next</IonRouterLink>
          )}
        </div>
      )}
    </div>
  );
};

export default Tips;
