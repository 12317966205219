import { Dentist } from "@/data/Dentists";
import { formatName } from "@/utils/helpers/formatName";
import { isClinicName } from "@/utils/helpers/isClinicName";

export const formatDentistName = ({
  dentist,
  returnDentistFirstName = true,
  returnDentistLastName = false,
  returnDentistFullName = false,
  returnClinicFirstName = false,
  returnClinicLastName = false,
  returnClinicFullName = true,
  withHonorific = true,
}: {
  dentist: Dentist | null | undefined;
  returnDentistFirstName?: boolean;
  returnDentistLastName?: boolean;
  returnDentistFullName?: boolean;
  returnClinicFirstName?: boolean;
  returnClinicLastName?: boolean;
  returnClinicFullName?: boolean;
  withHonorific?: boolean;
}) => {
  if (!dentist) return "";

  const isUsingClinicName = isClinicName(
    `${dentist.first_name} ${dentist.last_name}`,
  );

  if (isUsingClinicName) {
    if (returnClinicFirstName) {
      return `${formatName(dentist.first_name)}`;
    }

    if (returnClinicLastName) {
      return `${formatName(dentist.last_name)}`;
    }

    if (returnClinicFullName) {
      return `${formatName(dentist.first_name)} ${
        formatName(dentist.last_name)
      }`;
    }
  }

  if (returnDentistFullName) {
    return `${withHonorific ? "Dr." : ""} ${formatName(dentist.first_name)} ${
      formatName(dentist.last_name)
    }`;
  }

  if (returnDentistLastName) {
    return `${withHonorific ? "Dr." : ""} ${formatName(dentist.last_name)}`;
  }

  if (returnDentistFirstName) {
    return `${withHonorific ? "Dr." : ""} ${formatName(dentist.first_name)}`;
  }
};
