import {
  IonCheckbox,
  IonContent,
  IonHeader,
  IonModal,
  IonRouterLink,
  IonSearchbar
} from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';

import Empty from '@/components/common/empty/Empty';
import { SELECT_MODAL_OTHERS } from '@/utils/constants';

import './SelectModal.css';

interface SelectModalProps {
  isOpen: boolean;
  presentingElement?: HTMLElement | undefined;
  title?: string | React.ReactNode;
  options: string[];
  getLabel?: (option: string) => string;
  isSelected: (option: string) => boolean;
  isPreSelected?: (option: string) => boolean;
  onSelect: (option: string, isSelected: boolean) => void;
  onClose: () => void;
  showOthers?: boolean;
  multiple?: boolean;
}

const SelectModal: React.FC<SelectModalProps> = ({
  isOpen,
  presentingElement,
  title = 'Select an option',
  options,
  getLabel = (option: string) => option,
  isSelected,
  isPreSelected = () => false,
  onSelect,
  onClose,
  showOthers = true,
  multiple = false
}) => {
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [filteredOptions, setFilteredOptions] = useState<string[]>(options);

  const filterOptions = (searchQuery: string | null | undefined) => {
    if (!searchQuery) {
      setFilteredOptions(options);
    } else {
      const normalizedQuery = searchQuery.toLowerCase();
      setFilteredOptions(
        options.filter((option) =>
          getLabel(option).toLowerCase().includes(normalizedQuery)
        )
      );
    }
  };

  useEffect(() => {
    filterOptions(searchQuery);
  }, [searchQuery, options]);

  const emptyMessage = (
    <>
      No results found for "{searchQuery}",
      <br />
      you can select Others.
    </>
  );

  const onSelectCallback = useCallback(
    (option: string) => {
      onSelect(option, isSelected(option));
    },
    [onSelect, isSelected]
  );

  const onSearch = (event: CustomEvent) => {
    setSearchQuery(event.detail.value);
  };

  const isOthersSelected = isSelected(SELECT_MODAL_OTHERS);

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onClose}
      presentingElement={presentingElement}
    >
      <IonHeader
        className={`selectModalHeader ${presentingElement ? '' : 'withSafeArea'}`}
      >
        <h1 className="title">{title}</h1>
        {multiple && (
          <p className="subTitle">You can select multiple options</p>
        )}
        <div className="searchBar">
          <IonSearchbar
            onIonInput={onSearch}
            autoFocus={false}
            debounce={324}
          ></IonSearchbar>
          <IonRouterLink onClick={onClose}>Done</IonRouterLink>
        </div>
      </IonHeader>
      <IonContent className="selectModalContent ion-padding">
        <div className="options">
          {filteredOptions.sort().map((option, index) => {
            if (option === SELECT_MODAL_OTHERS && showOthers) return null;

            const selected = isSelected(option);
            const preSelected = isPreSelected(option);

            return (
              <IonCheckbox
                key={`selectModalOption-${index}`}
                className={`option ${selected ? 'selected' : ''} ${preSelected ? 'preSelected' : ''}`}
                checked={selected}
                onClick={() => onSelectCallback(option)}
              >
                <span className="optionLabel ion-text-wrap">
                  {getLabel(option)}
                </span>
              </IonCheckbox>
            );
          })}
          {showOthers && (
            <IonCheckbox
              key="selectModalOption-others"
              className={`option ${isOthersSelected ? 'selected' : ''}`}
              checked={isOthersSelected}
              onClick={() => onSelectCallback(SELECT_MODAL_OTHERS)}
            >
              <span className="optionLabel ion-text-wrap">
                {getLabel(SELECT_MODAL_OTHERS)}
              </span>
            </IonCheckbox>
          )}
        </div>
        {filteredOptions.length === 0 && <Empty message={emptyMessage} />}
      </IonContent>
    </IonModal>
  );
};

export default SelectModal;
