import {
  IonBadge,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonModal,
  NavContext
} from '@ionic/react';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';

import Empty from '@/components/common/empty/Empty';
import Loading from '@/components/common/loading/Loading';
import ModalHeader from '@/components/common/modalHeader/ModalHeader';
import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import Icon from '@/components/memo/icon/Icon';
import { useNotifications } from '@/contexts/NotificationsContext';
import { useRequests } from '@/contexts/RequestsContext';
import { useTabs } from '@/contexts/TabsContext';
import { DentistNotification } from '@/data/DentistNotifications';
import {
  INITIAL_NOTIFICATION_GROUPS_COUNT,
  NOTIFICATION_CATEGORIES,
  NOTIFICATION_CATEGORY_IDENTIFIERS,
  NOTIFICATION_TITLES,
  POPUP_IDENTIFIERS
} from '@/utils/constants';
import camelCase from '@/utils/helpers/camelCase';
import { formatNotificationTimestamp } from '@/utils/helpers/formatNotificationTimestamp';
import { ROUTES } from '@/utils/routes';
import { TABS } from '@/utils/tabs';
import { NotificationCategory } from '@/utils/types';
import { chevronForwardOutline, notificationsOutline } from 'ionicons/icons';
import moment from 'moment';

import './Notifications.css';

interface NotificationsProps {
  isOpen: boolean;
  onClose: () => void;
  presentingElement?: HTMLElement | undefined;
}

const Notifications: React.FC<NotificationsProps> = ({
  isOpen,
  onClose,
  presentingElement
}) => {
  const { navigate } = useContext(NavContext);
  const { notifications, groupedNotifications, loading, loaded } =
    useNotifications();
  const { setSelectedTab } = useTabs();
  const { requests } = useRequests();

  const modal = useRef<HTMLIonModalElement>(null);

  const [displayedGroups, setDisplayedGroups] = useState<number>(
    INITIAL_NOTIFICATION_GROUPS_COUNT
  );

  const dateGroups = useMemo(
    () => Object.keys(groupedNotifications.readByDate),
    [groupedNotifications.readByDate]
  );
  const hasUnreadGroup = useMemo(
    () => groupedNotifications.unread.length > 0,
    [groupedNotifications.unread]
  );
  const totalGroups = useMemo(
    () => dateGroups.length + (hasUnreadGroup ? 1 : 0),
    [dateGroups.length, hasUnreadGroup]
  );

  const loadMore = async (event: CustomEvent<void>) => {
    if (displayedGroups < totalGroups) {
      setDisplayedGroups((prev) => Math.min(prev + 1, totalGroups));
    }

    const infiniteScroll = event.target as HTMLIonInfiniteScrollElement;
    await infiniteScroll.complete();
  };

  const onNotificationClick = useCallback(
    (
      notification: DentistNotification,
      notificationCategory: NotificationCategory
    ) => {
      if (notificationCategory === NOTIFICATION_CATEGORIES.WEEKLY_REPORT) {
        navigate(`${ROUTES.PROFILE}?popup=${POPUP_IDENTIFIERS.WEEKLY_REPORT}`);
        setSelectedTab(TABS.PROFILE);
        onClose();
        return;
      }

      if (notification.request_id) {
        const request = requests.find(
          (request) => request.id === notification.request_id
        );
        if (request) {
          if (notificationCategory === NOTIFICATION_CATEGORIES.REQUEST) {
            navigate(
              `${ROUTES.REQUESTS}?query=${encodeURIComponent(
                request?.patient?.first_name || ''
              )} ${encodeURIComponent(request?.patient?.last_name || '')}&status=${
                request.status
              }&focusedRequestId=${request.id}`
            );
            setSelectedTab(TABS.REQUESTS);
            onClose();
            return;
          }
          navigate(
            `${ROUTES.CALENDAR}?selectedDate=${request.date}&focusedRequestId=${request.id}`
          );
          setSelectedTab(TABS.CALENDAR);
          onClose();
        }
      }
    },
    [navigate, requests, setSelectedTab, onClose]
  );

  const getNotificationCategory = (message: string) => {
    if (message.includes(NOTIFICATION_CATEGORY_IDENTIFIERS.REMINDER)) {
      return NOTIFICATION_CATEGORIES.REMINDER;
    } else if (message.includes(NOTIFICATION_CATEGORY_IDENTIFIERS.CANCELLED)) {
      return NOTIFICATION_CATEGORIES.CANCELLED;
    } else if (
      message.includes(NOTIFICATION_CATEGORY_IDENTIFIERS.RESCHEDULED)
    ) {
      return NOTIFICATION_CATEGORIES.RESCHEDULED;
    } else if (
      message.includes(NOTIFICATION_CATEGORY_IDENTIFIERS.WEEKLY_REPORT)
    ) {
      return NOTIFICATION_CATEGORIES.WEEKLY_REPORT;
    }
    return NOTIFICATION_CATEGORIES.REQUEST;
  };

  const getNotificationTitle = (category: string) => {
    switch (category) {
      case NOTIFICATION_CATEGORIES.REMINDER:
        return NOTIFICATION_TITLES.REMINDER;
      case NOTIFICATION_CATEGORIES.CANCELLED:
        return NOTIFICATION_TITLES.CANCELLED;
      case NOTIFICATION_CATEGORIES.RESCHEDULED:
        return NOTIFICATION_TITLES.RESCHEDULED;
      case NOTIFICATION_CATEGORIES.WEEKLY_REPORT:
        return NOTIFICATION_TITLES.WEEKLY_REPORT;
      default:
        return NOTIFICATION_TITLES.REQUEST;
    }
  };

  const getNotificationAction = (category: string) => {
    if (category === NOTIFICATION_CATEGORIES.WEEKLY_REPORT) {
      return 'View Report';
    }

    return category === NOTIFICATION_CATEGORIES.REQUEST
      ? 'View Request'
      : 'View Appointment';
  };

  const renderNotificationGroups = () => {
    const groups = [];
    let groupCount = 0;

    if (
      groupedNotifications.unread.length > 0 &&
      groupCount < displayedGroups
    ) {
      groups.push(
        <div key="unread" className="notificationListSection">
          <h3 className="notificationListTitle">
            Unread{' '}
            <IonBadge color="danger">
              {groupedNotifications.unread.length}
            </IonBadge>
          </h3>
          <div className="notificationListItems">
            {groupedNotifications.unread.map((notification) => {
              const notificationCategory = getNotificationCategory(
                notification.message
              );
              const notificationTitle =
                getNotificationTitle(notificationCategory);
              const notificationAction =
                getNotificationAction(notificationCategory);

              return (
                <div
                  key={notification.id}
                  className="notificationListItem"
                  onClick={() =>
                    onNotificationClick(notification, notificationCategory)
                  }
                >
                  <div className="notificationInformation">
                    <p
                      className={`notificationCategory ${camelCase(notificationCategory)}`}
                    >
                      {notificationCategory.toUpperCase()}
                    </p>
                    <p className="notificationTimestamp">
                      {formatNotificationTimestamp(notification.created_at)}
                    </p>
                  </div>
                  <div className="notificationContent">
                    <div className="notificationTitleContainer">
                      <div className="notificationUnreadBadge"></div>
                      <p className="notificationTitle">{notificationTitle}</p>
                    </div>
                    <p className="notificationMessage">
                      {notification.message.replace(
                        NOTIFICATION_CATEGORY_IDENTIFIERS.REMINDER,
                        ''
                      )}
                    </p>
                  </div>
                  <div className="notificationAction">
                    <p className="notificationActionText">
                      {notificationAction}
                    </p>
                    <Icon
                      icon={chevronForwardOutline}
                      className="notificationActionIcon"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
      groupCount++;
    }

    Object.keys(groupedNotifications.readByDate).forEach((date) => {
      if (groupCount < displayedGroups) {
        groups.push(
          <div key={date} className="notificationListSection">
            <h3 className="notificationListTitle">
              {moment(date, 'YYYY-MM-DD').isSame(moment(), 'day')
                ? 'Today'
                : moment(date, 'YYYY-MM-DD').format('MMMM D, YYYY')}
            </h3>
            <div className="notificationListItems">
              {groupedNotifications.readByDate[date].map((notification) => {
                const notificationCategory = getNotificationCategory(
                  notification.message
                );
                const notificationTitle =
                  getNotificationTitle(notificationCategory);
                const notificationAction =
                  getNotificationAction(notificationCategory);

                return (
                  <div
                    key={notification.id}
                    className="notificationListItem"
                    onClick={() =>
                      onNotificationClick(notification, notificationCategory)
                    }
                  >
                    <div className="notificationInformation">
                      <p
                        className={`notificationCategory ${camelCase(notificationCategory)}`}
                      >
                        {notificationCategory.toUpperCase()}
                      </p>
                      <p className="notificationTimestamp">
                        {formatNotificationTimestamp(notification.created_at)}
                      </p>
                    </div>
                    <div className="notificationContent">
                      <div className="notificationTitleContainer">
                        <p className="notificationTitle">{notificationTitle}</p>
                      </div>
                      <p className="notificationMessage">
                        {notification.message.replace(
                          NOTIFICATION_CATEGORY_IDENTIFIERS.REMINDER,
                          ''
                        )}
                      </p>
                    </div>
                    <div className="notificationAction">
                      <p className="notificationActionText">
                        {notificationAction}
                      </p>
                      <Icon
                        icon={chevronForwardOutline}
                        className="notificationActionIcon"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
        groupCount++;
      }
    });

    return groups;
  };

  const renderNotifications = () => {
    if (loading || !loaded) {
      return <Loading />;
    }

    if (notifications.length === 0) {
      return (
        <Empty
          icon={notificationsOutline}
          message={
            <>
              When you get notifications,
              <br />
              they'll show up here
            </>
          }
        />
      );
    }

    return (
      <>
        <Spacer top={24} />
        <div id="notificationList">{renderNotificationGroups()}</div>
        <IonInfiniteScroll
          onIonInfinite={loadMore}
          disabled={displayedGroups >= totalGroups}
        >
          <IonInfiniteScrollContent
            loadingSpinner="crescent"
            loadingText="Loading more..."
          />
        </IonInfiniteScroll>
      </>
    );
  };

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onClose}
      presentingElement={presentingElement}
      id="notifications"
      ref={modal}
    >
      <IonContent>
        <Padding withSafeArea={!presentingElement}>
          <ModalHeader
            title="Notifications"
            onClose={() => {
              modal.current?.dismiss();
            }}
          />
          {renderNotifications()}
        </Padding>
      </IonContent>
    </IonModal>
  );
};

export default Notifications;
