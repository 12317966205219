import {
  IonButton,
  IonContent,
  IonInput,
  IonLoading,
  IonModal,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Logo from '@/components/common/logo/Logo';
import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import Icon from '@/components/memo/icon/Icon';
import Text from '@/components/memo/text/Text';
import SelectModal from '@/components/modals/selectModal/SelectModal';
import { useDentist } from '@/contexts/DentistContext';
import {
  Service,
  createService,
  deleteService,
  getServiceOptions,
  getServicesByDentistId,
  updateService
} from '@/data/Services';
import useToast from '@/hooks/useToast';
import { SERVICE_DURATION_OPTIONS } from '@/utils/constants';
import { arrowBackOutline, checkmarkCircleOutline } from 'ionicons/icons';

import './UpdateServices.css';

interface UpdateServicesProps {
  isOpen: boolean;
  onClose: () => void;
  presentingElement?: HTMLElement | undefined;
}

const UpdateServices: React.FC<UpdateServicesProps> = ({
  isOpen,
  onClose,
  presentingElement
}) => {
  const displayToast = useToast();
  const { selectedDentist: dentist } = useDentist();

  const [initialServices, setInitialServices] = useState<Service[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [lastSavedServices, setLastSavedServices] = useState<Service[]>([]);
  const [serviceOptions, setServiceOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState<boolean>(false);
  const [selectModalPresentingElement, setSelectModalPresentingElement] =
    useState<HTMLElement | undefined>(undefined);

  const modal = useRef<HTMLIonModalElement | null>(null);

  useEffect(() => {
    setSelectModalPresentingElement(modal.current ?? undefined);
  }, [modal.current]);

  useEffect(() => {
    const fetchServices = async () => {
      if (dentist?.id) {
        try {
          const fetchedServices = await getServicesByDentistId(dentist.id);
          setServices(fetchedServices);
          setInitialServices(fetchedServices);
          setLastSavedServices(fetchedServices);
        } catch {
          displayToast({
            message: `Please check your internet connection.`,
            duration: 3000,
            position: 'bottom'
          });
        }
      }
    };
    fetchServices();
  }, [dentist?.id]);

  useEffect(() => {
    const fetchServiceOptions = async () => {
      try {
        const options = await getServiceOptions();
        setServiceOptions(options);
      } catch {
        displayToast({
          message: `Please check your internet connection.`,
          duration: 3000,
          position: 'bottom'
        });
      }
    };
    fetchServiceOptions();
  }, []);

  const copy = {
    subHeader: 'SERVICES',
    header: (
      <>
        Manage the <Text color="primary">services</Text> you offer
      </>
    ),
    message: `Please select all the services you offer and indicate the estimated duration of each service.`,
    buttonText: 'Update',
    skipText: 'Skip',
    inputLabel: 'Services',
    inputPlaceholder: 'Example: Braces Adjustment',
    modalTitle: (
      <>
        Select <Text color="primary">services</Text>
      </>
    )
  };

  const isSelected = (value: string) =>
    services.some((service) => service.name === value);

  const isPreSelected = (value: string) =>
    lastSavedServices.some((service) => service.name === value);

  const onSelect = (value: string, selected: boolean) => {
    setServices((prevServices) =>
      selected
        ? prevServices.filter((service) => service.name !== value)
        : [...prevServices, { name: value, duration: 30 }]
    );
  };

  const onCloseSelectModal = () => {
    setIsSelectModalOpen(false);
    setLastSavedServices(services);
  };

  const onDurationChange = (service: Service, value: number) => {
    setServices((prevServices) =>
      prevServices.map((s) =>
        s.name === service.name ? { ...s, duration: value } : s
      )
    );
  };

  const onNext = useCallback(async () => {
    if (services.length > 0 && dentist?.id) {
      setHasError(false);
      try {
        setLoading(true);
        for (const service of services) {
          if (
            initialServices.some(
              (initialService) => initialService.name === service.name
            )
          ) {
            await updateService(service.id, service);
          } else {
            await createService({ ...service, dentist_id: dentist?.id });
          }
        }
        for (const service of initialServices) {
          if (!services.some((s) => s.name === service.name)) {
            await deleteService(service.id!);
          }
        }
        setInitialServices(services);
        setLoading(false);
        displayToast({
          message: 'Services updated successfully.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
        onClose();
      } catch {
        setLoading(false);
        displayToast({
          message: 'Something went wrong. Please try again.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      }
    } else {
      setHasError(true);
      displayToast({
        message: 'Please select a valid service.',
        duration: 3000,
        position: 'bottom',
        positionAnchor: 'tabBar'
      });
    }
  }, [
    services,
    initialServices,
    dentist?.id,
    updateService,
    createService,
    onClose
  ]);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onClose}
      presentingElement={presentingElement}
      ref={modal}
    >
      <IonContent>
        <Padding withSafeArea={!presentingElement}>
          <div id="updateServices">
            <Logo leftIcon={arrowBackOutline} onClickLeftIcon={onClose} />
            <Spacer top={0} bottom={24}>
              <h4 id="subHeader">{copy.subHeader}</h4>
              <h1 id="header">{copy.header}</h1>
              <p id="message">{copy.message}</p>
            </Spacer>

            <Spacer bottom={124}>
              <div id="form">
                <div className={`input ${hasError ? 'error' : ''}`}>
                  <IonInput
                    id="serviceInput"
                    label={copy.inputLabel}
                    labelPlacement="stacked"
                    value={services.map((service) => service.name).join(', ')}
                    placeholder={copy.inputPlaceholder}
                    onClick={() => {
                      setHasError(false);
                      setIsSelectModalOpen(true);
                    }}
                    readonly
                  />
                </div>
              </div>
              {services.length > 0 && (
                <Spacer top={24}>
                  <div id="selectedServices">
                    {services
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((service, index) => (
                        <div key={index} className="selectedService">
                          <p className="serviceLabel">{service.name}</p>
                          <div className="input">
                            <IonSelect
                              label="Estimated Duration"
                              labelPlacement="stacked"
                              placeholder="Select duration"
                              value={service.duration}
                              onIonChange={(e: CustomEvent) =>
                                onDurationChange(service, e.detail.value)
                              }
                            >
                              {SERVICE_DURATION_OPTIONS.map((option) => (
                                <IonSelectOption
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </div>
                        </div>
                      ))}
                  </div>
                </Spacer>
              )}
            </Spacer>

            <ButtonContainer>
              <IonButton
                id="nextButton"
                onClick={onNext}
                expand="block"
                fill="solid"
                size="default"
                color="primary"
                strong={true}
                disabled={loading}
              >
                <Icon slot="start" icon={checkmarkCircleOutline} />
                {copy.buttonText}
              </IonButton>
            </ButtonContainer>
          </div>
        </Padding>
        <IonLoading isOpen={loading} message="Loading..." spinner="crescent" />
        <SelectModal
          isOpen={isSelectModalOpen}
          presentingElement={selectModalPresentingElement}
          title={copy.modalTitle}
          options={serviceOptions}
          isSelected={isSelected}
          isPreSelected={isPreSelected}
          onSelect={onSelect}
          onClose={onCloseSelectModal}
          multiple={true}
          showOthers={false}
        />
      </IonContent>
    </IonModal>
  );
};

export default UpdateServices;
