import { NavContext } from '@ionic/react';
import { useCallback, useEffect, useState } from 'react';
import { useContext } from 'react';

import { App } from '@capacitor/app';
import { URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications
} from '@capacitor/push-notifications';

import { useAuth } from '@/contexts/AuthenticationContext';
import { useDentist } from '@/contexts/DentistContext';
import useToast from '@/hooks/useToast';
import { DEEPLINK_DENTIST_ID_PARAMETER_KEY, DOMAIN } from '@/utils/constants';
import { ROUTES } from '@/utils/routes';

const DeepLinkListener: React.FC = () => {
  const displayToast = useToast();
  const { navigate } = useContext(NavContext);
  const { authenticated } = useAuth();
  const { dentists, selectDentist } = useDentist();
  const [queuedDentistIdForSelection, setQueuedDentistIdForSelection] =
    useState<string | null>(null);
  const [queuedNavigation, setQueuedNavigation] = useState<string | null>(null);

  const onDeepLink = useCallback((event: URLOpenListenerEvent) => {
    try {
      const [path, query] = event.url.split('?');
      const lowerCaseEventUrl = `${path.toLowerCase()}${query ? `?${query}` : ''}`;
      const eventUrl = lowerCaseEventUrl.startsWith('http')
        ? lowerCaseEventUrl
        : `https://${lowerCaseEventUrl}`;
      const url = new URL(eventUrl);

      if (
        url.origin.includes(DOMAIN) &&
        Object.values(ROUTES).includes(url.pathname)
      ) {
        const dentistId = url.searchParams.get(
          DEEPLINK_DENTIST_ID_PARAMETER_KEY
        );

        const path = url.pathname + url.search;

        if (dentistId) {
          setQueuedDentistIdForSelection(dentistId);
          setQueuedNavigation(path);
          return;
        }
      }
    } catch {
      displayToast({
        message: 'Failed to parse deep link URL.',
        position: 'top'
      });
    }
  }, []);

  useEffect(() => {
    App.addListener('appUrlOpen', onDeepLink);
    if (Capacitor.isNativePlatform()) {
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (event: ActionPerformed) => {
          const url = event.notification?.data?.url;
          if (url) {
            onDeepLink({ url });
          }
        }
      );
    }

    return () => {
      App.removeAllListeners();
      if (Capacitor.isNativePlatform()) {
        PushNotifications.removeAllListeners();
      }
    };
  }, [onDeepLink]);

  useEffect(() => {
    const handleQueuedDentistSelectionAndNavigation = async () => {
      if (authenticated && queuedDentistIdForSelection) {
        const dentist = dentists.find(
          (dentist) => dentist.id === queuedDentistIdForSelection
        );
        if (dentist) {
          await selectDentist(dentist);
          setQueuedDentistIdForSelection(null);
          if (queuedNavigation) {
            navigate(queuedNavigation);
            setQueuedNavigation(null);
          }
        }
      }
    };

    handleQueuedDentistSelectionAndNavigation();
  }, [
    authenticated,
    dentists,
    queuedDentistIdForSelection,
    queuedNavigation,
    navigate,
    selectDentist
  ]);

  return null;
};

export default DeepLinkListener;
