import {
  IonButton,
  IonContent,
  IonInput,
  IonLoading,
  IonModal,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import React, { useRef } from 'react';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Logo from '@/components/common/logo/Logo';
import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import Icon from '@/components/memo/icon/Icon';
import Text from '@/components/memo/text/Text';
import {
  RescheduleProvider,
  useReschedule
} from '@/contexts/RescheduleContext';
import { Request } from '@/data/Requests';
import { arrowBackOutline, warning } from 'ionicons/icons';
import moment from 'moment';

import './Reschedule.css';

interface RescheduleProps {
  isOpen: boolean;
  onClose: () => void;
  requestToReschedule?: Request | null;
  setSelectedDate?: React.Dispatch<React.SetStateAction<Date>>;
  presentingElement?: HTMLElement | undefined;
}

const Reschedule: React.FC<RescheduleProps> = ({
  isOpen,
  onClose,
  presentingElement
}) => {
  const {
    date,
    startTime,
    endTime,
    reason,
    loading,
    errors,
    onInput,
    submit,
    reset,
    timeOptions,
    conflictingRequest,
    inputRef,
    onKeyDown
  } = useReschedule();

  const modal = useRef<HTMLIonModalElement | null>(null);

  const copy = {
    subHeader: 'RESCHEDULE',
    header: (
      <>
        Reschedule <Text color="primary">appointment</Text>
      </>
    ),
    message: 'Please select a new date and time for the appointment.',
    datePlaceholder: 'Select a date',
    startTimePlaceholder: 'Select a start time',
    endTimePlaceholder: 'Select an end time',
    reasonPlaceholder: 'Enter reason for rescheduling'
  };

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={() => {
        reset();
        onClose();
      }}
      presentingElement={presentingElement}
      ref={modal}
    >
      <IonContent>
        <Padding withSafeArea={!presentingElement}>
          <div id="reschedule">
            <Logo
              leftIcon={arrowBackOutline}
              onClickLeftIcon={() => {
                reset();
                modal.current?.dismiss();
              }}
            />
            <Spacer top={0} bottom={24}>
              <h4 id="subHeader">{copy.subHeader}</h4>
              <h1 id="header">{copy.header}</h1>
              <p id="message">{copy.message}</p>
            </Spacer>

            <Spacer bottom={124}>
              <div id="form">
                <div className={`input ${errors.date ? 'error' : ''}`}>
                  <IonInput
                    ref={inputRef}
                    label="Date"
                    labelPlacement="stacked"
                    value={date}
                    type="date"
                    placeholder={copy.datePlaceholder}
                    onIonInput={(e: CustomEvent) =>
                      onInput('date', e.detail.value!)
                    }
                    onKeyDown={onKeyDown}
                    min={moment().format('YYYY-MM-DD')}
                    max={
                      moment().endOf('year').isAfter(moment().add(3, 'months'))
                        ? moment().endOf('year').format('YYYY-MM-DD')
                        : moment()
                            .add(3, 'months')
                            .endOf('year')
                            .format('YYYY-MM-DD')
                    }
                  />
                </div>
                <div className={`input ${errors.startTime ? 'error' : ''}`}>
                  <IonSelect
                    label="Start Time"
                    labelPlacement="stacked"
                    value={startTime}
                    compareWith={(a: string, b: string) =>
                      moment(a, 'HH:mm').isSame(moment(b, 'HH:mm'))
                    }
                    placeholder={copy.startTimePlaceholder}
                    onIonChange={(e: CustomEvent) =>
                      onInput('startTime', e.detail.value!)
                    }
                  >
                    {timeOptions.slice(0, -1).map((time) => (
                      <IonSelectOption key={time} value={time}>
                        {moment(time, 'HH:mm').format('h:mm A')}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </div>
                <div className={`input ${errors.endTime ? 'error' : ''}`}>
                  <IonSelect
                    label="End Time"
                    labelPlacement="stacked"
                    value={endTime}
                    compareWith={(a: string, b: string) =>
                      moment(a, 'HH:mm').isSame(moment(b, 'HH:mm'))
                    }
                    placeholder={copy.endTimePlaceholder}
                    onIonChange={(e: CustomEvent) =>
                      onInput('endTime', e.detail.value!)
                    }
                  >
                    {timeOptions
                      .filter(
                        (time) =>
                          !startTime ||
                          moment(time, 'HH:mm').isAfter(
                            moment(startTime, 'HH:mm')
                          )
                      )
                      .map((time) => (
                        <IonSelectOption key={time} value={time}>
                          {moment(time, 'HH:mm').format('h:mm A')}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </div>
                <div className="input">
                  <IonInput
                    label="Reason (optional)"
                    labelPlacement="stacked"
                    value={reason}
                    type="text"
                    placeholder={copy.reasonPlaceholder}
                    onIonInput={(e: CustomEvent) =>
                      onInput('reason', e.detail.value!)
                    }
                    onKeyDown={onKeyDown}
                  />
                </div>
                {conflictingRequest && (
                  <div className="conflictWarning">
                    <Icon icon={warning} />
                    <Text color="warning">
                      <strong>Caution:</strong> The selected timeslot overlaps
                      with an existing appointment. You may continue, but ensure
                      this is intentional.
                    </Text>
                  </div>
                )}
              </div>
            </Spacer>

            <ButtonContainer>
              <IonButton
                id="rescheduleButton"
                onClick={submit}
                expand="block"
                fill="solid"
                size="default"
                color="primary"
                strong={true}
              >
                Reschedule Appointment
              </IonButton>
            </ButtonContainer>
          </div>
        </Padding>
        <IonLoading isOpen={loading} message="Loading..." spinner="crescent" />
      </IonContent>
    </IonModal>
  );
};

const RescheduleWithProvider: React.FC<RescheduleProps> = (props) => {
  return (
    <RescheduleProvider {...props}>
      <Reschedule {...props} />
    </RescheduleProvider>
  );
};

export default RescheduleWithProvider;
