import { IonBadge, IonLabel, IonPopover, IonRouterLink } from '@ionic/react';
import { NavContext } from '@ionic/react';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import Avatar from '@/components/common/avatar/Avatar';
import Icon from '@/components/memo/icon/Icon';
import { useAuth } from '@/contexts/AuthenticationContext';
import { useClinic } from '@/contexts/ClinicContext';
import { useDentist } from '@/contexts/DentistContext';
import { useNotifications } from '@/contexts/NotificationsContext';
import { useTabs } from '@/contexts/TabsContext';
import { generateRandomString } from '@/utils/helpers/generateRandomString';
import { getInitials } from '@/utils/helpers/getInitials';
import { ROUTES } from '@/utils/routes';
import {
  logOutOutline,
  notificationsOutline,
  swapHorizontalOutline
} from 'ionicons/icons';

import './HeaderIcons.css';

interface HeaderIconsProps {
  tab: string;
  presentingElement?: HTMLElement | undefined;
}

const HeaderIcons: React.FC<HeaderIconsProps> = ({
  tab,
  presentingElement
}) => {
  const { signOut } = useAuth();
  const { selectDentist, selectedDentist: dentist } = useDentist();
  const { isClinic } = useClinic();
  const { navigate } = useContext(NavContext);
  const {
    onOpenNotifications,
    unreadCount,
    setNotificationsPresentingElement
  } = useNotifications();
  const { selectedTab } = useTabs();

  const active = useMemo(() => tab === selectedTab, [tab, selectedTab]);

  const onSwitchDentist = useCallback(async () => {
    await selectDentist(null);
    navigate(ROUTES.SELECT_DENTIST);
  }, [selectDentist, navigate]);

  const onSignOut = useCallback(async () => {
    await selectDentist(null);
    signOut();
  }, [selectDentist, signOut]);

  const triggerId = useMemo(() => generateRandomString(), []);
  const popoverId = useMemo(() => generateRandomString(), []);

  useEffect(() => {
    if (active) {
      setNotificationsPresentingElement(presentingElement);
    }
  }, [active, setNotificationsPresentingElement, presentingElement]);

  return (
    <div className="headerIcons">
      <div className="notificationIcon" onClick={onOpenNotifications}>
        <Icon icon={notificationsOutline} />
        {unreadCount > 0 && <IonBadge color="danger">{unreadCount}</IonBadge>}
      </div>
      <div className={`avatarIcon ${triggerId}`} id={triggerId}>
        <Avatar initials={getInitials(dentist)} />
      </div>
      <IonPopover
        id={popoverId}
        trigger={triggerId}
        triggerAction="click"
        className="avatarPopover"
        reference="trigger"
        side="bottom"
        alignment="end"
        arrow={false}
        dismissOnSelect={true}
      >
        <div className="avatarPopoverContent" role="menu">
          <IonRouterLink
            className="avatarPopoverItem"
            onClick={onSwitchDentist}
            role="menuitem"
            tabIndex={0}
          >
            <div className="avatarPopoverItemContent">
              <Icon icon={swapHorizontalOutline} />
              <IonLabel>Switch {isClinic ? 'Dentist' : 'Clinic'}</IonLabel>
            </div>
          </IonRouterLink>
          <IonRouterLink
            className="avatarPopoverItem"
            onClick={onSignOut}
            role="menuitem"
            tabIndex={0}
          >
            <div className="avatarPopoverItemContent">
              <Icon icon={logOutOutline} />
              <IonLabel>Sign Out</IonLabel>
            </div>
          </IonRouterLink>
        </div>
      </IonPopover>
    </div>
  );
};

export default HeaderIcons;
