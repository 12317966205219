import {
  IonButton,
  IonCheckbox,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import React, { useCallback, useState } from 'react';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Spacer from '@/components/common/spacer/Spacer';
import Icon from '@/components/memo/icon/Icon';
import { useAddDentist } from '@/contexts/AddDentistContext';
import { DentistHour } from '@/data/DentistHours';
import useToast from '@/hooks/useToast';
import { DAY_NAMES_ARRAY } from '@/utils/constants';
import { arrowForwardCircleOutline } from 'ionicons/icons';
import moment from 'moment';

import './Hours.css';

interface HoursProps {}

const Hours: React.FC<HoursProps> = () => {
  const { onAddDentist } = useAddDentist();
  const displayToast = useToast();

  const copy = {
    subHeader: 'WORKING HOURS',
    header: (
      <>
        Set your <span>working</span> hours
      </>
    ),
    message:
      'Please check all the days you are working and specify the working hours for each day.'
  };

  const initialHours = Array.from({ length: 7 }, (_, day) => ({
    day,
    start_time: null,
    end_time: null,
    open: false
  }));

  const [hours, setHours] = useState<DentistHour[]>(initialHours);

  const timeOptions = Array.from({ length: 48 }, (_, i) => {
    const hours = String(Math.floor((i + 8) / 2) % 24).padStart(2, '0');
    const minutes = (i + 12) % 2 === 0 ? '00' : '30';
    return `${hours}:${minutes}`;
  });

  const onTimeChange = (
    day: number,
    type: 'start_time' | 'end_time',
    value: string
  ) => {
    setHours((prevHours) =>
      prevHours.map((hour) =>
        hour.day === day || !hour.open ? { ...hour, [type]: value } : hour
      )
    );
  };

  const onCheckboxChange = (day: number, open: boolean) => {
    setHours((prevHours) =>
      prevHours.map((hour) => (hour.day === day ? { ...hour, open } : hour))
    );
  };

  const onNext = useCallback(async () => {
    const validHours = hours.filter(
      (hour) =>
        !hour.open ||
        (hour.start_time &&
          hour.end_time &&
          moment(hour.end_time, 'HH:mm').isAfter(
            moment(hour.start_time, 'HH:mm')
          ))
    );
    if (validHours.length > 0) {
      await onAddDentist(validHours);
    } else {
      displayToast({
        message: 'Please add at least one valid working day and specify hours.',
        duration: 5000,
        position: 'bottom',
        positionAnchor: 'nextButton'
      });
    }
  }, [hours, onAddDentist]);

  return (
    <div id="hours">
      <Spacer top={32} bottom={24}>
        <h4 id="subHeader">{copy.subHeader}</h4>
        <h1 id="header">{copy.header}</h1>
        <p id="message">{copy.message}</p>
      </Spacer>

      <Spacer bottom={124}>
        <div id="workingDays">
          {hours.map((hour) => (
            <div key={hour.day} className="workingDay">
              <IonCheckbox
                className="day"
                checked={hour.open}
                onIonChange={(e: CustomEvent) =>
                  onCheckboxChange(hour.day, e.detail.checked)
                }
              >
                <p className="dayLabel">{DAY_NAMES_ARRAY[hour.day]}</p>
              </IonCheckbox>
              {hour.open && (
                <>
                  <Spacer top={12} />
                  <div className="workingHours">
                    <div className="input">
                      <IonSelect
                        label="Start Time"
                        labelPlacement="stacked"
                        value={hour.start_time}
                        placeholder="Select a time"
                        onIonChange={(e: CustomEvent) =>
                          onTimeChange(hour.day, 'start_time', e.detail.value)
                        }
                      >
                        {timeOptions.map((time) => (
                          <IonSelectOption key={time} value={time}>
                            {moment(time, 'HH:mm').format('h:mm A')}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </div>
                    <div className="input">
                      <IonSelect
                        label="End Time"
                        labelPlacement="stacked"
                        value={hour.end_time}
                        placeholder="Select a time"
                        onIonChange={(e: CustomEvent) =>
                          onTimeChange(hour.day, 'end_time', e.detail.value)
                        }
                      >
                        {timeOptions
                          .filter(
                            (time) =>
                              !hour.start_time ||
                              moment(time, 'HH:mm').isAfter(
                                moment(hour.start_time, 'HH:mm')
                              )
                          )
                          .map((time) => (
                            <IonSelectOption key={time} value={time}>
                              {moment(time, 'HH:mm').format('h:mm A')}
                            </IonSelectOption>
                          ))}
                      </IonSelect>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </Spacer>

      <ButtonContainer>
        <IonButton
          id="nextButton"
          onClick={onNext}
          expand="block"
          fill="solid"
          size="default"
          color="primary"
          strong={true}
        >
          <Icon slot="start" icon={arrowForwardCircleOutline} />
          Next
        </IonButton>
      </ButtonContainer>
    </div>
  );
};

export default Hours;
