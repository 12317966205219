import { IonContent, IonLoading, IonPage } from '@ionic/react';
import React from 'react';

import MobileNumber from '@/components/auth/signIn/mobileNumber/MobileNumber';
import Verification from '@/components/auth/signIn/verification/Verification';
import Logo from '@/components/common/logo/Logo';
import Padding from '@/components/common/padding/Padding';
import ProgressBar from '@/components/common/progressBar/ProgressBar';
import { SignInProvider, useSignIn } from '@/contexts/SignInContext';
import { SIGN_IN_STEPS } from '@/utils/constants';
import { arrowBackOutline } from 'ionicons/icons';

import './SignIn.css';

const SignIn: React.FC = () => {
  const { loading, currentStep, setCurrentStep, goBack } = useSignIn();

  const onBack = () => {
    if (currentStep === SIGN_IN_STEPS.MOBILE_NUMBER) {
      goBack();
      return;
    }

    const previousStepIndex =
      Object.values(SIGN_IN_STEPS).findIndex((step) => step === currentStep) -
      1;
    const newStep = Object.values(SIGN_IN_STEPS)[previousStepIndex];
    if (newStep) {
      setCurrentStep(newStep);
    }
  };

  const renderSignIn = () => {
    const totalSteps = Object.keys(SIGN_IN_STEPS).length;
    const currentStepIndex = Object.values(SIGN_IN_STEPS).indexOf(currentStep);

    const stepComponents = {
      [SIGN_IN_STEPS.MOBILE_NUMBER]: <MobileNumber />,
      [SIGN_IN_STEPS.VERIFICATION]: <Verification />
    };

    return (
      <>
        <ProgressBar
          totalSteps={totalSteps}
          currentProgress={currentStepIndex}
        />
        {stepComponents[currentStep]}
      </>
    );
  };

  return (
    <IonPage id="signIn">
      <IonContent>
        <Padding>
          <Logo leftIcon={arrowBackOutline} onClickLeftIcon={onBack} />
          {renderSignIn()}
        </Padding>
      </IonContent>

      <IonLoading isOpen={loading} message="Loading..." spinner="crescent" />
    </IonPage>
  );
};

const SignInWithProvider: React.FC = () => (
  <SignInProvider>
    <SignIn />
  </SignInProvider>
);

export default SignInWithProvider;
