import { NavContext } from '@ionic/react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { User } from '@supabase/supabase-js';

import { useNetwork } from './NetworkContext';
import { useTracker } from '@/contexts/TrackerContext';
import { useVersion } from '@/contexts/VersionContext';
import { User as UserRecord, createUser, getUser } from '@/data/Users';
import useToast from '@/hooks/useToast';
import { DEBOUNCE_TIME_IN_MS } from '@/utils/constants';
import { DEBOUNCE_SETTINGS } from '@/utils/constants';
import { ROUTES } from '@/utils/routes';
import { supabase } from '@/utils/supabase';
import debounce from 'lodash/debounce';

interface AuthContextType {
  authenticated: boolean;
  user: User | null;
  signIn: (showLoading?: boolean) => Promise<UserRecord | null>;
  signOut: () => void;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { navigate } = useContext(NavContext);
  const { connected } = useNetwork();
  const { captureEvent, identifyUser, resetTracker } = useTracker();
  const { forceUpdate, maintenanceModeEnabled } = useVersion();

  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const displayToast = useToast();

  const fetchUser = debounce(
    async () => {
      if (loading) return;

      try {
        setLoading(true);
        const {
          data: { user },
          error
        } = await supabase.auth.getUser();

        if (error) {
          throw new Error(error.message);
        }

        if (user) {
          setUser(user);
          setAuthenticated(true);
          identifyUser({ distinctId: user.id });
          captureEvent({ event: 'user signed in' });
        } else {
          setUser(null);
          setAuthenticated(false);
          throw new Error('User not found.');
        }
      } catch {
        setUser(null);
        setAuthenticated(false);
        resetTracker();
      } finally {
        setLoading(false);
      }
    },
    DEBOUNCE_TIME_IN_MS,
    DEBOUNCE_SETTINGS
  );

  const signIn = useCallback(
    async (showLoading: boolean = true) => {
      try {
        if (showLoading) setLoading(true);
        const {
          data: { user },
          error
        } = await supabase.auth.getUser();

        if (error) {
          throw new Error(error.message);
        }

        if (!user) {
          throw new Error('User not found.');
        }

        const existingUser = await getUser(user.id);
        if (!existingUser) {
          const newUser = await createUser({
            id: user.id,
            notification_tokens: null
          });
          if (newUser) {
            setUser(user);
            setAuthenticated(true);
            identifyUser({ distinctId: user.id });
            captureEvent({ event: 'user signed in' });

            return newUser;
          } else {
            throw new Error('User not created.');
          }
        }
        setUser(user);
        setAuthenticated(true);
        identifyUser({ distinctId: user.id });
        captureEvent({ event: 'user signed in' });

        return existingUser;
      } catch (error) {
        displayToast({
          message: 'Error logging in. Please contact support.',
          position: 'bottom'
        });
        return null;
      } finally {
        if (showLoading) setLoading(false);
      }
    },
    [identifyUser, captureEvent]
  );

  const signOut = useCallback(
    async (showLoading: boolean = true) => {
      try {
        if (showLoading) setLoading(true);
        const { error } = await supabase.auth.signOut();

        if (error) {
          throw new Error(error.message);
        }

        setUser(null);
        setAuthenticated(false);
        resetTracker();
        navigate(ROUTES.WELCOME);
      } catch {
        displayToast({
          message: 'Error logging out. Please contact support.',
          position: 'bottom'
        });
      } finally {
        if (showLoading) setLoading(false);
      }
    },
    [resetTracker, navigate]
  );

  useEffect(() => {
    if (connected) {
      fetchUser();
    }
  }, [connected]);

  useEffect(() => {
    if (!connected || forceUpdate || maintenanceModeEnabled) {
      setLoading(false);
    }
  }, [forceUpdate, maintenanceModeEnabled, connected]);

  useEffect(() => {
    return () => {
      setUser(null);
      setAuthenticated(false);
      setLoading(false);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        user,
        signIn,
        signOut,
        loading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
