import { IonContent, IonLoading, IonPage } from '@ionic/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Logo from '@/components/common/logo/Logo';
import Padding from '@/components/common/padding/Padding';
import ProgressBar from '@/components/common/progressBar/ProgressBar';
import Hours from '@/components/profiles/addDentist/hours/Hours';
import MobileNumber from '@/components/profiles/addDentist/mobileNumber/MobileNumber';
import Name from '@/components/profiles/addDentist/name/Name';
import Services from '@/components/profiles/addDentist/services/Services';
import {
  AddDentistProvider,
  useAddDentist
} from '@/contexts/AddDentistContext';
import { ADD_DENTIST_STEPS } from '@/utils/constants';
import { arrowBackOutline } from 'ionicons/icons';

import './AddDentist.css';

const AddDentist: React.FC = () => {
  const { loading, currentStep, goBack, onReset, setCurrentStep } =
    useAddDentist();

  const [presentingElement, setPresentingElement] = useState<
    HTMLElement | undefined
  >(undefined);

  const page = useRef<HTMLElement | undefined>(undefined);

  useEffect(() => {
    setPresentingElement(page.current);
  }, [page.current]);

  const onBack = useCallback(() => {
    if (currentStep === ADD_DENTIST_STEPS.NAME) {
      goBack();
    } else if (currentStep === ADD_DENTIST_STEPS.MOBILE_NUMBER) {
      setCurrentStep(ADD_DENTIST_STEPS.NAME);
    } else {
      onReset();
    }
  }, [currentStep, goBack, onReset]);

  const renderAddDentist = () => {
    const totalSteps = Object.keys(ADD_DENTIST_STEPS).length;
    const currentStepIndex =
      Object.values(ADD_DENTIST_STEPS).indexOf(currentStep);

    const stepComponents = {
      [ADD_DENTIST_STEPS.NAME]: <Name />,
      [ADD_DENTIST_STEPS.MOBILE_NUMBER]: <MobileNumber />,
      [ADD_DENTIST_STEPS.HOURS]: <Hours />,
      [ADD_DENTIST_STEPS.SERVICES]: (
        <Services presentingElement={presentingElement} />
      )
    };

    return (
      <>
        <ProgressBar
          totalSteps={totalSteps}
          currentProgress={currentStepIndex}
        />
        {stepComponents[currentStep]}
      </>
    );
  };

  return (
    <IonPage id="addDentist" ref={page}>
      <IonContent>
        <Padding>
          <Logo
            leftIcon={
              currentStep === ADD_DENTIST_STEPS.SERVICES
                ? null
                : arrowBackOutline
            }
            onClickLeftIcon={onBack}
          />
          {renderAddDentist()}
        </Padding>
      </IonContent>

      <IonLoading isOpen={loading} message="Loading..." spinner="crescent" />
    </IonPage>
  );
};

const AddDentistWithProvider: React.FC = () => (
  <AddDentistProvider>
    <AddDentist />
  </AddDentistProvider>
);

export default AddDentistWithProvider;
