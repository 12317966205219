import { IonButton, IonContent, IonPage, NavContext } from '@ionic/react';
import { useCallback, useContext } from 'react';

import logo from '@/assets/images/logo/logo-with-icon.png';
import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Padding from '@/components/common/padding/Padding';
import Promo from '@/components/common/promo/Promo';
import Text from '@/components/memo/text/Text';
import { useDentist } from '@/contexts/DentistContext';
import { ROUTES } from '@/utils/routes';

import './NoAccess.css';

const NoAccess: React.FC = () => {
  const { selectDentist } = useDentist();
  const { navigate } = useContext(NavContext);

  const copy = {
    promo: {
      header: (
        <>
          No <Text color="primary">access</Text> given!
        </>
      ),
      subHeader:
        'Please contact your clinic administrator to request access to your schedule.'
    },
    backButtonText: 'Go back'
  };

  const onBack = useCallback(async () => {
    await selectDentist(null);
    navigate(ROUTES.SELECT_DENTIST);
  }, [selectDentist, navigate]);

  return (
    <IonPage id="noAccess">
      <IonContent>
        <Padding>
          <div id="promoContainer">
            <Promo
              image={logo}
              imageStyle={{ maxWidth: 192 }}
              header={copy.promo.header}
              subHeader={copy.promo.subHeader}
            />
          </div>
          <ButtonContainer>
            <IonButton
              id="backButton"
              onClick={onBack}
              expand="block"
              fill="solid"
              size="default"
              color="primary"
              strong={true}
            >
              {copy.backButtonText}
            </IonButton>
          </ButtonContainer>
        </Padding>
      </IonContent>
    </IonPage>
  );
};

export default NoAccess;
