// useDimensions.ts
import { useEffect, useState } from "react";

const getDimensions = () => {
  if (!window) {
    return {
      width: null,
      height: null,
    };
  }

  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useDimensions = () => {
  const [dimensions, setDimensions] = useState<{
    width: number | null;
    height: number | null;
  }>(getDimensions());

  useEffect(() => {
    const onResize = () => {
      setDimensions(getDimensions());
    };

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return dimensions;
};

export default useDimensions;
