import { NavContext } from '@ionic/react';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { TABS } from '@/utils/tabs';

interface TabsContextType {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
  onTabButtonClick: (tab: string) => void;
  onTabChange: (event: CustomEvent) => void;
  onSignOut: () => void;
}

const TabsContext = createContext<TabsContextType | undefined>(undefined);

interface TabsProviderProps {
  children: React.ReactNode;
}

export const TabsProvider: React.FC<TabsProviderProps> = ({ children }) => {
  const { routeInfo } = useContext(NavContext);

  const defaultTab = TABS.CALENDAR;
  const [selectedTab, setSelectedTab] = useState<string>(defaultTab);

  const onTabButtonClick = (tab: string) => {
    setSelectedTab(tab);
  };

  const onTabChange = (event: CustomEvent) => {
    setSelectedTab(event.detail.tab);
  };

  const onSignOut = () => {
    setSelectedTab(defaultTab);
  };

  useEffect(() => {
    if (routeInfo?.pathname) {
      const path = routeInfo.pathname;
      const tab = path.split('/')[1];
      setSelectedTab(tab || defaultTab);
    }
  }, [routeInfo?.pathname]);

  return (
    <TabsContext.Provider
      value={{
        selectedTab,
        setSelectedTab,
        onTabButtonClick,
        onTabChange,
        onSignOut
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

export const useTabs = () => {
  const context = useContext(TabsContext);
  if (context === undefined) {
    throw new Error('useTabs must be used within a TabsProvider');
  }
  return context;
};
