import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { useAuth } from '@/contexts/AuthenticationContext';
import { Clinic, getClinic } from '@/data/Clinics';

interface ClinicContextType {
  selectedClinic: Clinic | null;
  selectClinic: (clinic: Clinic | null) => Promise<void>;
  fetchClinic: () => Promise<void>;
  isClinic: boolean | undefined;
  loading: boolean;
  loaded: boolean;
}

const ClinicContext = createContext<ClinicContextType | undefined>(undefined);

interface ClinicProviderProps {
  children: React.ReactNode;
}

export const ClinicProvider: React.FC<ClinicProviderProps> = ({ children }) => {
  const { user } = useAuth();

  const [selectedClinic, setSelectedClinic] = useState<Clinic | null>(null);
  const [isClinic, setIsClinic] = useState<boolean | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const fetchClinic = useCallback(async () => {
    try {
      setLoading(true);
      const clinic = await getClinic(user?.id);
      if (clinic) {
        setSelectedClinic(clinic);
        setIsClinic(true);
      } else {
        setIsClinic(false);
      }
      setLoading(false);
      setLoaded(true);
    } catch {
      setLoading(false);
      setLoaded(true);
    }
  }, [user?.id]);

  const selectClinic = useCallback(
    async (clinic: Clinic | null) => {
      if (clinic !== null && isClinic === undefined) return;

      setSelectedClinic(clinic);

      if (clinic === null) setLoaded(false);
    },
    [isClinic]
  );

  useEffect(() => {
    if (!user?.id) selectClinic(null);
  }, [user?.id, selectClinic]);

  useEffect(() => {
    return () => {
      setSelectedClinic(null);
      setIsClinic(undefined);
      setLoading(false);
      setLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (!user?.id) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [user?.id]);

  return (
    <ClinicContext.Provider
      value={{
        selectedClinic,
        selectClinic,
        fetchClinic,
        isClinic,
        loading,
        loaded
      }}
    >
      {children}
    </ClinicContext.Provider>
  );
};

export const useClinic = () => {
  const context = useContext(ClinicContext);
  if (context === undefined) {
    throw new Error('useClinic must be used within a ClinicProvider');
  }
  return context;
};
