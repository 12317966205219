import { IonBadge } from '@ionic/react';
import React from 'react';

import Avatar from '@/components/common/avatar/Avatar';
import { Dentist } from '@/data/Dentists';
import { formatDentistName } from '@/utils/helpers/formatDentistName';
import { getInitials } from '@/utils/helpers/getInitials';
import { isClinicName } from '@/utils/helpers/isClinicName';

import './DentistTile.css';

interface DentistTileProps {
  dentist: Dentist;
  onSelectDentist: (dentist: Dentist) => void;
}

const DentistTile: React.FC<DentistTileProps> = ({
  dentist,
  onSelectDentist
}) => {
  const badge = dentist.badge || 0;
  const isUsingClinicName = isClinicName(
    `${dentist.first_name} ${dentist.last_name}`
  );

  return (
    <div className="dentistTile" onClick={() => onSelectDentist(dentist)}>
      {badge > 0 && (
        <IonBadge className="dentistBadge" color="danger">
          {badge}
        </IonBadge>
      )}
      <Avatar className="dentistAvatar" initials={getInitials(dentist)} />
      <p className="dentistName">
        {formatDentistName({ dentist, returnClinicFirstName: true })}
      </p>
      {dentist.clinic && dentist.clinic.name && !isUsingClinicName && (
        <p className="clinicName">{dentist.clinic.name}</p>
      )}
      {isUsingClinicName && (
        <p className="clinicName">
          {formatDentistName({ dentist, returnClinicLastName: true })}
        </p>
      )}
    </div>
  );
};

export default DentistTile;
