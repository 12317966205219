import React from 'react';

import HeaderIcons from '@/components/common/headerIcons/HeaderIcons';

import './DashboardHeader.css';

interface DashboardHeaderProps {
  title: string | React.ReactNode;
  tab: string;
  showIcons?: boolean;
  presentingElement?: HTMLElement | undefined;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  title,
  tab,
  showIcons = true,
  presentingElement
}) => {
  return (
    <div id="dashboardHeader">
      <h1 id="dashboardHeaderTitle">{title}</h1>
      {showIcons && (
        <HeaderIcons tab={tab} presentingElement={presentingElement} />
      )}
    </div>
  );
};

export default DashboardHeader;
