import React, { memo } from 'react';

interface SpacerProps {
  className?: string;
  children?: React.ReactNode;
  top?: number; // Space above the component (in px, e.g., 10)
  bottom?: number; // Space below the component (in px, e.g., 10)
  left?: number; // Space to the left of the component (in px, e.g., 10)
  right?: number; // Space to the right of the component (in px, e.g., 10)
}

const Spacer = ({
  className,
  top = 0,
  bottom = 0,
  left = 0,
  right = 0,
  children
}: SpacerProps) => {
  return (
    <div
      className={className}
      style={{
        paddingTop: top >= 0 ? `${top}px` : undefined,
        paddingBottom: bottom >= 0 ? `${bottom}px` : undefined,
        paddingLeft: left >= 0 ? `${left}px` : undefined,
        paddingRight: right >= 0 ? `${right}px` : undefined,
        marginTop: top < 0 ? `${top}px` : undefined,
        marginBottom: bottom < 0 ? `${bottom}px` : undefined,
        marginLeft: left < 0 ? `${left}px` : undefined,
        marginRight: right < 0 ? `${right}px` : undefined
      }}
    >
      {children}
    </div>
  );
};

export default memo(Spacer);
