import {
  IonButton,
  IonContent,
  IonPage,
  IonRouterLink,
  NavContext
} from '@ionic/react';
import { useContext } from 'react';

import { Browser } from '@capacitor/browser';

import logo from '@/assets/images/logo/logo-with-icon.png';
import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Padding from '@/components/common/padding/Padding';
import Promo from '@/components/common/promo/Promo';
import Text from '@/components/memo/text/Text';
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '@/utils/constants';
import { ROUTES } from '@/utils/routes';

import './Welcome.css';

const Welcome: React.FC = () => {
  const { navigate } = useContext(NavContext);

  const openTermsOfService = () => {
    Browser.open({ url: TERMS_OF_SERVICE_URL });
  };

  const openPrivacyPolicy = () => {
    Browser.open({ url: PRIVACY_POLICY_URL });
  };

  const copy = {
    getStartedButtonText: 'Get started',
    signInButtonText: 'Log in',
    note: (
      <Text>
        By clicking Get started, you agree to our{' '}
        <IonRouterLink color="primary" onClick={openTermsOfService}>
          Terms of Service
        </IonRouterLink>
        {' and '}
        <IonRouterLink color="primary" onClick={openPrivacyPolicy}>
          Privacy Policy
        </IonRouterLink>
        .
      </Text>
    )
  };

  return (
    <IonPage id="welcome">
      <IonContent>
        <Padding>
          <div id="promoContainer">
            <Promo image={logo} />
          </div>
          <ButtonContainer>
            <IonButton
              id="getStartedButton"
              onClick={() => navigate(ROUTES.GET_STARTED)}
              expand="block"
              fill="solid"
              size="default"
              color="primary"
              strong={true}
            >
              {copy.getStartedButtonText}
            </IonButton>
            <IonButton
              id="signInButton"
              onClick={() => navigate(ROUTES.SIGN_IN)}
              expand="block"
              fill="clear"
              size="default"
              color="primary"
              strong={true}
            >
              {copy.signInButtonText}
            </IonButton>
            <p id="note">{copy.note}</p>
          </ButtonContainer>
        </Padding>
      </IonContent>
    </IonPage>
  );
};

export default Welcome;
