import React, {
  KeyboardEventHandler,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { useDentist } from '@/contexts/DentistContext';
import { useRequests } from '@/contexts/RequestsContext';
import { Request, rescheduleRequest, updateRequest } from '@/data/Requests';
import { useInputFocus } from '@/hooks/useInputFocus';
import useToast from '@/hooks/useToast';
import { REQUEST_STATUS_OPTIONS } from '@/utils/constants';
import moment from 'moment';

interface RescheduleContextProps {
  date: string | null;
  setDate: React.Dispatch<React.SetStateAction<string | null>>;
  startTime: string | null;
  setStartTime: React.Dispatch<React.SetStateAction<string | null>>;
  endTime: string | null;
  setEndTime: React.Dispatch<React.SetStateAction<string | null>>;
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  errors: any;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onInput: (field: string, value: any) => void;
  submit: () => Promise<void>;
  reset: () => void;
  onKeyDown: KeyboardEventHandler<HTMLIonInputElement>;
  conflictingRequest: Request | null;
  inputRef: React.RefObject<HTMLIonInputElement>;
  timeOptions: string[];
}

const RescheduleContext = createContext<RescheduleContextProps | undefined>(
  undefined
);

interface RescheduleProviderProps {
  isOpen?: boolean;
  onClose?: () => void;
  setSelectedDate?: React.Dispatch<React.SetStateAction<Date>>;
  requestToReschedule?: Request | null;
  children: React.ReactNode;
}

export const RescheduleProvider: React.FC<RescheduleProviderProps> = ({
  onClose,
  setSelectedDate,
  requestToReschedule,
  children
}) => {
  const { selectedDentist: dentist, dentistHours } = useDentist();
  const { requests, setRequests, onUpdateStatus, setFocusedRequestId } =
    useRequests();
  const displayToast = useToast();

  const [date, setDate] = useState<string | null>(null);
  const [startTime, setStartTime] = useState<string | null>(null);
  const [endTime, setEndTime] = useState<string | null>(null);
  const [reason, setReason] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, boolean>>({
    date: false,
    startTime: false,
    endTime: false
  });
  const [conflictingRequest, setConflictingRequest] = useState<Request | null>(
    null
  );

  const inputRef = useRef<HTMLIonInputElement>(null);

  useInputFocus(inputRef);

  const getTimeOptions = (start: string, end: string) => {
    const startTime = moment(start, 'HH:mm');
    const endTime = moment(end, 'HH:mm');
    const calculatedTimeOptions = [];

    while (startTime.isBefore(endTime)) {
      calculatedTimeOptions.push(startTime.format('HH:mm'));
      startTime.add(30, 'minutes');
    }

    return calculatedTimeOptions;
  };

  const timeOptions = useMemo(() => {
    if (dentistHours.length > 0) {
      const dayOfWeek = moment(date).day();
      const hoursForDay = dentistHours.find((hours) => hours.day === dayOfWeek);
      if (hoursForDay && hoursForDay.start_time && hoursForDay.end_time) {
        return getTimeOptions(hoursForDay.start_time, hoursForDay.end_time);
      }
    }
    return Array.from({ length: 48 }, (_, i) => {
      const hours = String(Math.floor((i + 8) / 2) % 24).padStart(2, '0');
      const minutes = (i + 12) % 2 === 0 ? '00' : '30';
      return `${hours}:${minutes}`;
    });
  }, [dentistHours, date]);

  const onInput = (field: string, value: any) => {
    switch (field) {
      case 'date':
        setDate(value);
        break;
      case 'startTime':
        setStartTime(value);
        break;
      case 'endTime':
        setEndTime(value);
        break;
      case 'reason':
        setReason(value);
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
  };

  const reset = () => {
    setDate(null);
    setStartTime(null);
    setEndTime(null);
    setReason('');
    setErrors({
      date: false,
      startTime: false,
      endTime: false
    });
  };

  const submit = useCallback(async () => {
    try {
      const newErrors = {
        date: !date,
        startTime: !startTime,
        endTime: !endTime
      };

      setErrors(newErrors);

      if (Object.values(newErrors).some((error) => error)) {
        displayToast({
          message: 'Please fill in all required fields.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'rescheduleButton'
        });
        return;
      }

      if (!dentist?.id) {
        setErrors((prevErrors) => ({ ...prevErrors, dentist: true }));
        displayToast({
          message: 'Please select a dentist.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'rescheduleButton'
        });
        return;
      }

      if (!requestToReschedule?.id) {
        displayToast({
          message: 'No appointment selected for rescheduling.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'rescheduleButton'
        });
        return;
      }

      setLoading(true);

      const updatedRequest = await updateRequest({
        requestId: requestToReschedule.id,
        request: {
          date,
          start_time: startTime,
          end_time: endTime,
          purpose: requestToReschedule.purpose,
          status: requestToReschedule.status,
          dentist_id: requestToReschedule.dentist_id,
          patient_id: requestToReschedule.patient_id
        }
      });

      if (updatedRequest) {
        setRequests(
          requests.map((request) =>
            request.id === updatedRequest.id
              ? { ...request, ...updatedRequest }
              : request
          )
        );
        await rescheduleRequest(
          requestToReschedule.id,
          reason.trim() === '' ? null : reason.trim()
        );
        onUpdateStatus(requestToReschedule, REQUEST_STATUS_OPTIONS.RESCHEDULED);
        displayToast({
          message:
            'Appointment rescheduled. A notification has been sent to your patient.',
          duration: 5000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
        setLoading(false);
        reset();
        if (onClose) {
          onClose();
        }
        if (setSelectedDate) {
          setSelectedDate(moment(date).startOf('day').toDate());
        }
        if (updatedRequest.id) {
          setFocusedRequestId(updatedRequest.id);
        }
      }
    } catch {
      setLoading(false);
      displayToast({
        message:
          'Failed to reschedule appointment. Please check your internet connection and try again.',
        duration: 3000,
        position: 'bottom',
        positionAnchor: 'rescheduleButton'
      });
    }
  }, [
    date,
    startTime,
    endTime,
    reason,
    dentist,
    requestToReschedule,
    requests,
    setRequests,
    displayToast,
    reset,
    onClose,
    setSelectedDate,
    setFocusedRequestId
  ]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLIonInputElement>) => {
      if (event.key === 'Enter') {
        submit();
      }
    },
    [submit]
  );

  useEffect(() => {
    return () => {
      setDate(null);
      setStartTime(null);
      setEndTime(null);
      setReason('');
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    const checkConflictingRequest = () => {
      if (!date || !startTime || !endTime) return;

      const slotStartTime = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm');
      const slotEndTime = moment(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm');

      const conflict = requests
        .filter(
          (request) =>
            request.id !== requestToReschedule?.id &&
            moment(date).isSame(moment(request.date, 'YYYY-MM-DD'), 'day') &&
            request.status === REQUEST_STATUS_OPTIONS.APPROVED
        )
        .find((request) => {
          const requestStartTime = moment(
            `${request.date} ${request.start_time}`,
            'YYYY-MM-DD HH:mm'
          );
          const requestEndTime = moment(
            `${request.date} ${request.end_time}`,
            'YYYY-MM-DD HH:mm'
          );

          return (
            slotStartTime.isBetween(
              requestStartTime,
              requestEndTime,
              null,
              '[)'
            ) ||
            slotEndTime.isBetween(
              requestStartTime,
              requestEndTime,
              null,
              '(]'
            ) ||
            requestStartTime.isBetween(
              slotStartTime,
              slotEndTime,
              null,
              '[)'
            ) ||
            requestEndTime.isBetween(slotStartTime, slotEndTime, null, '(]')
          );
        });

      setConflictingRequest(conflict ?? null);
    };

    checkConflictingRequest();
  }, [date, startTime, endTime, requests, requestToReschedule]);

  return (
    <RescheduleContext.Provider
      value={{
        date,
        setDate,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        reason,
        setReason,
        errors,
        setErrors,
        loading,
        setLoading,
        onInput,
        submit,
        reset,
        onKeyDown,
        conflictingRequest,
        inputRef,
        timeOptions
      }}
    >
      {children}
    </RescheduleContext.Provider>
  );
};

export const useReschedule = () => {
  const context = useContext(RescheduleContext);
  if (context === undefined) {
    throw new Error('useReschedule must be used within a RescheduleProvider');
  }
  return context;
};
